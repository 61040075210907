import { Component, OnInit } from "@angular/core";
@Component({
    selector: "privacy-statement-dialog-body",
    templateUrl: "./privacy-statement-dialog-body.component.html",
})
export class PrivacyStatementDialogBodyComponent implements OnInit {

    ngOnInit(): void {
        // code smell
    }

    scrollToElementById(elemntId: string) {
        const element = document.getElementById(elemntId)
        if (!element) {
            console.error(`no element with id ${elemntId}`)
        } else {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }
}
