export class FileResponseDto {
    file: string;
    mimeType: string;
    fileName: string;

    static fromObj(obj: any): FileResponseDto {
        if (!obj) {
            return null;
        }
        const fileResponseDto:  FileResponseDto = new FileResponseDto();
        fileResponseDto.file = obj.file;
        fileResponseDto.mimeType = obj.mimeType;
        fileResponseDto.fileName = obj.fileName;
        return fileResponseDto;
    }
}
