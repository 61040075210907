import {IResponseErrorDto} from './i-response-error-dto';

export class UpdateLastLogonResponseDto {

    static fromObj(obj: any): UpdateLastLogonResponseDto | null {
        if (!obj) {
            return null;
        }
        return new UpdateLastLogonResponseDto();
    }
}

export class UpdateLastLogonResponseErrorDto implements IResponseErrorDto {
    hasGenericUnprocessableEntityError = false;

    static fromObj(obj: any): UpdateLastLogonResponseErrorDto | null {
        if (!obj) {
            return null;
        }
        const updateLastLogonResponseErrorDto = new UpdateLastLogonResponseErrorDto();
        updateLastLogonResponseErrorDto.hasGenericUnprocessableEntityError = obj.hasGenericUnprocessableEntityError;
        return updateLastLogonResponseErrorDto;
    }
}
