import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef, TemplateRef} from '@angular/core';
import {EuiTableComponent, PaginationEvent, SortEvent} from '@eui/components/eui-table';
import {FormControl, FormGroup} from '@angular/forms';
import {TableFilters} from './models/table-filters.model';
import {EuiIconToggleComponent} from '@eui/components/eui-icon-toggle';
import {EuiPaginatorComponent} from '@eui/components/eui-paginator/eui-paginator.component';
import {SubstanceListItemDto} from '../../../features/substances/dtos/fetch-substances-list-response.dto';
import {Totals} from '../../../features/cases/dtos/totals.dto';
import {PageToolbarService} from '../page-toolbar/page-toolbar.service';
import {Subscription} from 'rxjs';
import {AllCasesColumns, AuthoritiesColumns, CompletedPossibleValues, FilterType, GridColumnType, LicitTradesTableColumns} from '../../enums/enums';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'dg-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('tableRef') table: EuiTableComponent;
    @ViewChildren('mergeToggle') mergeToggles: QueryList<EuiIconToggleComponent>;
    @ViewChild('paginator') paginator: EuiPaginatorComponent;
    @ViewChild('mergeColumn', { read: ViewContainerRef }) mergeColumnRef: ViewContainerRef;
    @ViewChild('mergeColumnContent', { read: TemplateRef }) mergeColumnContentRef: TemplateRef<any>;

    @Output() viewRow = new EventEmitter<any>();
    @Output() editRow = new EventEmitter<any>();
    @Output() deleteRow = new EventEmitter<any>();
    @Output() paginationEvent = new EventEmitter<PaginationEvent>();
    @Output() sortEvent = new EventEmitter<SortEvent>();
    @Output() filterEvent = new EventEmitter<TableFilters[]>();

    @Output() mergeToggleClick = new EventEmitter<{substance: SubstanceListItemDto; e: any}>();
    @Output() getFile = new EventEmitter<string>();

    @Input() data: any[] = [];
    @Input() columnsToDisplay: string[] = [];
    @Input() idField: string;
    @Input() totals: Totals = null;
    @Input() showTotals = false;
    @Input() totalResults: number = null;
    @Input() merge: SubstanceListItemDto[] = null;
    @Input() showMergeColumn: boolean = true;
    @Input() showMergeColumnToggle: boolean = true;
    @Input() hasPaginator = true;
    @Input() shouldDisplaySortableHeaders = true;
    @Input() shouldDisplayActionsHeader = true;
    @Input() shouldDisplayActions = true;
    @Input() filters: TableFilters[];
    @Input() showingFilters = false;
    @Input() formDPart1Table = false;
    @Input() canEditSpecificResourceKey = '';
    @Input() canEditSpecificResource = false;
    @Input() canDeleteSpecificResourceKey = '';
    @Input() canDeleteSpecificResource = false;
    @Input() isComplete = false;
    @Input() showView = true;
    @Input() showEdit = true;
    @Input() showDelete = true;
    @Input() columnHeaderMap: Map<string, string>; // Override display header name
    @Input() columnTypeMap: Map<string, GridColumnType> = new Map();
    @Input() actionTooltipText: string ='';
    @Input() viewTooltipText: string ='';
    @Input() editTooltipText: string ='';
    @Input() deleteTooltipText: string ='';

    readonly GridColumnType = GridColumnType;

    LicitTradesTableColumns = LicitTradesTableColumns;
    AuthoritiesColumns = AuthoritiesColumns;
    AllCasesColumns = AllCasesColumns;

    selectedRow: any = null;
    filtersForm: FormGroup;

    completed = CompletedPossibleValues.COMPLETED;
    notCompleted = CompletedPossibleValues.NOT_COMPLETED;
    na = CompletedPossibleValues.NA;
    FilterType = FilterType;

    invalidSubstance = 'substanceIsObsoleteOrDeleted';
    invalidSubstanceTooltip: string;

    scale = 'scale';

    totalsColspanOffset: number = 6;

    private toolbarSubscription: Subscription;

    constructor(private pageToolbarService: PageToolbarService, private translateService: TranslateService,){}

    ngOnInit(): void {
        this.initializeFiltersForm();
        this.toolbarSubscription = this.pageToolbarService.toolbars.subscribe({
            next: (casesFilter) => {
                if(casesFilter){
                    if(casesFilter.resetClicked) {
                        this.setNullValuesFiltersForm();
                    }
                }
            }
        });
        this.totalsColspanOffset = this.columnsToDisplay.findIndex(col => col === "cases");
        this.invalidSubstanceTooltip = this.translateService.instant('dg.grow.table.tooltip.substance.invalid');
    }

    initializeFiltersForm() {
        if(this.filters) {
            this.filtersForm = new FormGroup({});
            for(const filter of this.filters) {
                this.filtersForm.addControl(filter.key, new FormControl({value: null, disabled: false}));
            }
        }
    }

    setNullValuesFiltersForm() {
        if(this.filters && this.filtersForm) {
            for(const filter of this.filters) {
                this.filtersForm.get(filter.key)?.patchValue(null);
            }
        }
    }

    getScale(header:string, row: any): number {
        switch (this.columnTypeMap.get(header)) {
            case GridColumnType.SCALE_3:
                return 3;

            case GridColumnType.SCALE_5:
                return 5;

            case GridColumnType.DECIMAL:
                if(row[this.scale]){
                    return row[this.scale];
                }
                return 3;

            case GridColumnType.INTEGER:
                return 0;

            default:
                return 0;
        }
    }

    ngOnDestroy(){
        if(this.toolbarSubscription) {
            this.toolbarSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if(this.mergeToggles && changes['merge']?.currentValue.every((e) => e === null)) {
            for(const toggle of this.mergeToggles) {
                if(toggle.isChecked) {
                    toggle.onToggle();
                }
            }
        }

        if(this.mergeToggles && changes['showMergeColumnToggle']?.currentValue !== changes['showMergeColumnToggle']?.previousValue) {
            this.mergeColumnRef.clear();
            this.mergeColumnRef.createEmbeddedView(this.mergeColumnContentRef);
        }
    }

    public getHeader(columnName: string): string{
        if(this.columnHeaderMap && this.columnHeaderMap.has(columnName)){
            return this.columnHeaderMap.get(columnName);
        }
        return columnName;
    }

    public sortOn(columnName: string): string {
        // When has paginator, the sort field cannot have object.property format as the list is requested to the server and the response is sorted
        if(!columnName || !this.data?.length || this.hasPaginator) {
            return columnName;
        }

        // Assuming table data consistency
        const [firstItem] = this.data ?? [];
        const isObjectColumn = typeof firstItem[columnName] === 'object';
        return  isObjectColumn ? `${columnName}.name`: columnName;
    }

    public setSort(columnName: string, direction: 'asc' | 'desc'): void {
        if (!columnName || !this.data?.length) {
            this.table?.setSort(null, direction);
            return;
        }

        const sortKey = this.sortOn(columnName);
        this.table?.setSort(sortKey, direction);
    }

    public onViewRow(row: any) {
        this.selectedRow = row;
        this.viewRow.emit(row);
    }

    public onEditRow(row: any) {
        this.selectedRow = row;
        this.editRow.emit(row);
    }

    public onDeleteRow(row: any) {
        this.selectedRow = row;
        this.deleteRow.emit(row);
    }

    public endEdit() {
        this.filterEvent.emit(this.filtersForm.value);
    }

    public getFileLink(url: string){
        this.getFile.emit(url);
    }

    public emitMergeToggleClick(substance: SubstanceListItemDto, e: any) {
        this.mergeToggleClick.emit({substance, e});
    }
}
