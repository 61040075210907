import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AdvancedFilterNonNumericType, AdvancedFilterNumericType, AdvancedFilterRules, AllCasesColumns, AllCasesTypeOfAuthority, AllSubstancesColumns, AllUploadsColumns, AuthoritiesColumns, ColumnType, CompletedPossibleValues, EncodeCasesColumns, KeywordType, OperatorsColumns, Pages, PermissionsColumns, RolesColumns, StatusColumnPossibleValues, UsersColumns, YesNoPossibleValues} from '../../enums/enums';
import {FetchAdvancedFilterOptions} from './models/fetch-advanced-filters-options.model';

@Injectable({
    providedIn: 'root'
})
export class AdvancedFilterService {

    constructor(
        protected http: HttpClient
    ){}

    advancedFilterSubject: Subject<boolean> = new Subject<boolean>();

    setAdvancedFilterSubject() {
        this.advancedFilterSubject.next(true);
    }

    fetchAdvancedFilter(page: string): FetchAdvancedFilterOptions | null {
        const jsonObj = this.createCommonObjectProperties();
        switch (page) {
            case Pages.ENCODE_CASES:
                return this.fetchAdvancedFilterOptionsEncodeCases(jsonObj);
            case Pages.ALL_EU_CASES:
                return this.fetchAdvancedFilterOptionsAllEUCases(jsonObj);
            case Pages.REGISTRATIONS_LICENCES:
                return this.fetchAdvancedFilterOptionsOperators(jsonObj);
            case Pages.AUTHORITIES:
                return this.fetchAdvancedFilterOptionsAuthorities(jsonObj);
            case Pages.SUBSTANCES:
                return this.fetchAdvancedFilterOptionsSubstances();
            case Pages.USERS:
                return this.fetchAdvancedFilterOptionsUsers(jsonObj);
            case Pages.ROLES:
                return this.fetchAdvancedFilterOptionsRoles(jsonObj);
            case Pages.PERMISSIONS:
                return this.fetchAdvancedFilterOptionsPermissions(jsonObj);
            case Pages.UPLOADS:
                return this.fetchAdvancedFilterOptionsUploads();
            default:
                return null;
        }
    }

    private createCommonObjectProperties(): any {
        return {
            filterTypes: {
                numeric :[
                    { value: AdvancedFilterNumericType.EQUALS, label: 'dg.grow.advanced.filter.numeric.type.equals', selected: true},
                    { value: AdvancedFilterNumericType.IS_GREATER_THAN, label: 'dg.grow.advanced.filter.numeric.type.greater.than' },
                    { value: AdvancedFilterNumericType.IS_LESS_THAN, label: 'dg.grow.advanced.filter.numeric.type.less.than'},
                ],
                nonNumeric :[
                    { value: AdvancedFilterNonNumericType.CONTAINS, label: 'dg.grow.advanced.filter.non.numeric.type.contains', selected: true},
                    { value: AdvancedFilterNonNumericType.EQUALS, label: 'dg.grow.advanced.filter.non.numeric.type.equals'},
                ],
                categorical :[
                    { value: AdvancedFilterNonNumericType.EQUALS, label: 'dg.grow.advanced.filter.non.numeric.type.equals', selected: true},
                ],
            },
            rules: [
                {value: AdvancedFilterRules.ALL_RULES, label: 'dg.grow.advanced.filter.rules.all', selected: true},
                {value: AdvancedFilterRules.ANY_RULE, label: 'dg.grow.advanced.filter.rules.any'}
            ],
            keywordValues: {
                yesNo: [
                    { value: YesNoPossibleValues.YES, label: 'dg.grow.advanced.keyword.yes', selected: true},
                    { value: YesNoPossibleValues.NO, label: 'dg.grow.advanced.keyword.no'}
                ],
                status: [
                    { value: StatusColumnPossibleValues.ACTIVE, label: 'dg.grow.advanced.keyword.active', selected: true},
                    { value: StatusColumnPossibleValues.INACTIVE, label: 'dg.grow.advanced.keyword.inactive'}
                ],
                completed: [
                    { value: CompletedPossibleValues.COMPLETED, label: 'dg.grow.advanced.keyword.completed', selected: true},
                    { value: CompletedPossibleValues.NOT_COMPLETED, label: 'dg.grow.advanced.keyword.not.completed'},
                    { value: CompletedPossibleValues.NA, label: 'dg.grow.advanced.keyword.na'}
                ],
                typeOfAuthority: [
                    { value: AllCasesTypeOfAuthority.CUSTOMS, label: 'dg.grow.advanced.keyword.customs', selected: true},
                    { value: AllCasesTypeOfAuthority.NON_CUSTOMS, label: 'dg.grow.advanced.keyword.non.customs'},
                    { value: AllCasesTypeOfAuthority.NA, label: 'dg.grow.column.value.na'}

                ]
            }
        };
    }

    private fetchAdvancedFilterOptionsEncodeCases(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: EncodeCasesColumns.SUBSTANCE_NAME, label: 'dg.grow.table.header.substanceName', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.SUBSTANCE_TYPE, label: 'dg.grow.table.header.substanceType', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.TYPE_OF_EVENT, label: 'dg.grow.table.header.typeOfEvent', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.CASES, label: 'dg.grow.table.header.cases', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.KILOGRAM, label: 'dg.grow.table.header.kilogram', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.LITRE, label: 'dg.grow.table.header.litre', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.GRAM, label: 'dg.grow.table.header.gram', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.MILLILITRE, label: 'dg.grow.table.header.millilitre', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: EncodeCasesColumns.INTRA_EXTRA_EU_TRADE, label: 'dg.grow.table.header.intraExtraEuTrade', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT }
            ]
        };

        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsAllEUCases(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: AllCasesColumns.SUBSTANCE_NAME, label: 'dg.grow.table.header.substanceName', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.SUBSTANCE_TYPE, label: 'dg.grow.table.header.substanceType', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.TYPE_OF_EVENT, label: 'dg.grow.table.header.typeOfEvent', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.TYPE_OF_AUTHORITY, label: 'dg.grow.table.header.typeOfAuthority', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TYPE_OF_AUTHORITY },
                { value: AllCasesColumns.COUNTRY, label: 'dg.grow.table.header.country', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.COMPETENT_AUTHORITY, label: 'dg.grow.table.header.competentAuthority.full', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.YEAR_QUARTER, label: 'dg.grow.table.header.yearQuarter.full', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.CASES, label: 'dg.grow.table.header.cases', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.KILOGRAM, label: 'dg.grow.table.header.kilogram', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.LITRE, label: 'dg.grow.table.header.litre', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.GRAM, label: 'dg.grow.table.header.gram', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.MILLILITRE, label: 'dg.grow.table.header.millilitre', columnType: ColumnType.NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.INTRA_EXTRA_EU_TRADE, label: 'dg.grow.table.header.intraExtraEuTrade', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.CREATION_DATE, label: 'dg.grow.table.header.creationDate', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TEXT },
                { value: AllCasesColumns.LAST_UPDATE, label: 'dg.grow.table.header.lastUpdate', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TEXT },
            ]
        };
        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsUsers(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: UsersColumns.LOGIN_NAME, label: 'dg.grow.table.header.loginName', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: UsersColumns.NAME_SURNAME, label: 'dg.grow.table.header.nameSurname', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: UsersColumns.EMAIL, label: 'dg.grow.table.header.email', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: UsersColumns.CA_NAME, label: 'dg.grow.table.header.caName', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: UsersColumns.COUNTRY, label: 'dg.grow.table.header.country', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: UsersColumns.LAST_LOGON, label: 'dg.grow.table.header.lastLogon', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TEXT },
                { value: UsersColumns.STATUS, label: 'dg.grow.table.header.status', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.STATUS },
            ]
        };
        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsRoles(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: RolesColumns.CODE, label: 'dg.grow.table.header.code', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: RolesColumns.ROLE_NAME, label: 'dg.grow.table.header.roleName', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: RolesColumns.STATUS, label: 'dg.grow.table.header.status', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.STATUS },
            ]
        };
        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsPermissions(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: PermissionsColumns.CODE, label: 'dg.grow.table.header.code', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: PermissionsColumns.NAME, label: 'dg.grow.table.header.name', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: PermissionsColumns.STATUS, label: 'dg.grow.table.header.status', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.STATUS },
            ]
        };
        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsAuthorities(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: AuthoritiesColumns.REFERENCE, label: 'dg.grow.table.header.reference', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AuthoritiesColumns.COUNTRY, label: 'dg.grow.table.header.country', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AuthoritiesColumns.CA_NAME, label: 'dg.grow.table.header.caName', columnType: ColumnType.NON_NUMERIC , keywordType: KeywordType.TEXT},
                { value: AuthoritiesColumns.FORM_D_REPORTING, label: 'dg.grow.table.header.formDReporting', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.YESNO },
                { value: AuthoritiesColumns.OPERATORS, label: 'dg.grow.table.header.operators', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.YESNO },
                { value: AuthoritiesColumns.STATUS, label: 'dg.grow.table.header.status', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.STATUS},
                { value: AuthoritiesColumns.Q1, label: 'dg.grow.table.header.q1', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.COMPLETED },
                { value: AuthoritiesColumns.Q2, label: 'dg.grow.table.header.q2', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.COMPLETED },
                { value: AuthoritiesColumns.Q3, label: 'dg.grow.table.header.q3', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.COMPLETED },
                { value: AuthoritiesColumns.Q4, label: 'dg.grow.table.header.q4', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.COMPLETED },
                { value: AuthoritiesColumns.FORM_D, label: 'dg.grow.table.header.formD', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.COMPLETED  }
            ]
        };

        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    //TODO implement correct json object
    private fetchAdvancedFilterOptionsSubstances(): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            filterTypes: {
                numeric :[],
                nonNumeric :[
                    { value: AdvancedFilterNonNumericType.CONTAINS, label: 'dg.grow.advanced.filter.non.numeric.type.contains', selected: true},
                    { value: AdvancedFilterNonNumericType.EQUALS, label: 'dg.grow.advanced.filter.non.numeric.type.equals'}
                ],
                categorical :[
                    { value: AdvancedFilterNonNumericType.EQUALS, label: 'dg.grow.advanced.filter.non.numeric.type.equals', selected: true},
                ],
            },
            keywordValues: {
                status: [
                    { value: StatusColumnPossibleValues.ACTIVE, label: 'dg.grow.advanced.keyword.active', selected: true},
                    { value: StatusColumnPossibleValues.INACTIVE, label: 'dg.grow.advanced.keyword.inactive'}
                ],
            },
            columns: [
                { value: AllSubstancesColumns.SUBSTANCE_NAME, label: 'dg.grow.table.header.substanceName', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT},
                { value: AllSubstancesColumns.SUBSTANCE_TYPE, label: 'dg.grow.table.header.substanceType', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.SUBSTANCE_CATEGORY, label: 'dg.grow.table.header.substanceCategory', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.UN_TABLE, label: 'dg.grow.table.header.unTable', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT  },
                { value: AllSubstancesColumns.CN_DESIGNATION, label: 'dg.grow.table.header.cnDesignation', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.CN_CODE, label: 'dg.grow.table.header.cnCode', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.CAS_NO, label: 'dg.grow.table.header.casNo', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.UNIT, label: 'dg.grow.table.header.unit', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.STATUS, label: 'dg.grow.table.header.status', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.STATUS },
                { value: AllSubstancesColumns.VALID_UNTIL, label: 'dg.grow.table.header.validUntil', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TEXT },
                { value: AllSubstancesColumns.LAST_UPDATED, label: 'dg.grow.table.header.lastUpdate', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TEXT}
            ],
            rules: [
                { value: AdvancedFilterRules.ALL_RULES, label: 'dg.grow.advanced.filter.rules.all', selected: true },
                { value: AdvancedFilterRules.ANY_RULE, label: 'dg.grow.advanced.filter.rules.any' }
            ]
        };
        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsOperators(object: any): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            ...object,
            columns: [
                { value: OperatorsColumns.OPERATOR_NAME, label: 'dg.grow.table.header.operatorName', selected: true, columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: OperatorsColumns.COUNTRY, label: 'dg.grow.table.header.country', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: OperatorsColumns.CA, label: 'dg.grow.table.header.ca', columnType: ColumnType.NON_NUMERIC , keywordType: KeywordType.TEXT},
                { value: OperatorsColumns.REFERENCE, label: 'dg.grow.table.header.reference', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: OperatorsColumns.STATUS, label: 'dg.grow.table.header.status', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.STATUS},
            ]
        };

        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }

    private fetchAdvancedFilterOptionsUploads(): FetchAdvancedFilterOptions | null {
        const jsonObj = {
            filterTypes: {
                nonNumeric :[
                    { value: AdvancedFilterNonNumericType.CONTAINS, label: 'dg.grow.advanced.filter.non.numeric.type.contains', selected: true},
                    { value: AdvancedFilterNonNumericType.EQUALS, label: 'dg.grow.advanced.filter.non.numeric.type.equals'}
                ],
                categorical :[
                    { value: AdvancedFilterNonNumericType.EQUALS, label: 'dg.grow.advanced.filter.non.numeric.type.equals', selected: true},
                ],
            },
            columns: [
                { value: AllUploadsColumns.COUNTRY, label: 'dg.grow.table.header.country', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT, selected: true },
                { value: AllUploadsColumns.USERNAME, label: 'dg.grow.table.header.username', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllUploadsColumns.FILE_NAME, label: 'dg.grow.table.header.file.name', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT },
                { value: AllUploadsColumns.TYPE, label: 'dg.grow.table.header.type', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT},
                { value: AllUploadsColumns.DATE_OF_CREATION, label: 'dg.grow.table.header.date.of.creation', columnType: ColumnType.CATEGORICAL, keywordType: KeywordType.TEXT  },
                { value: AllUploadsColumns.NUMBER_OF_LINES, label: 'dg.grow.table.header.number.of.lines', columnType: ColumnType.NON_NUMERIC, keywordType: KeywordType.TEXT }
            ],
            rules: [
                { value: AdvancedFilterRules.ALL_RULES, label: 'dg.grow.advanced.filter.rules.all', selected: true },
                { value: AdvancedFilterRules.ANY_RULE, label: 'dg.grow.advanced.filter.rules.any' }
            ]
        };
        return FetchAdvancedFilterOptions.fromObj(jsonObj);
    }
}
