import {Pages, RecordsValues} from '../../../enums/enums';

export type FetchPageToolbarDataResponseDto = FetchPageToolbarDataEncodeCasesResponseDto
    | FetchPageToolbarDataFormDResponseDto
    | FetchPageToolbarDataAuthoritiesResponseDto
    | FetchPageToolbarDataRegistrationsLicensesResponseDto
    | FetchPageToolbarDataReportsResponseDto;

export class FetchPageToolbarDataEncodeCasesResponseDto {
    yearQuarter: FilterOption[];

    constructor(yearQuarter: FilterOption[]) {
        this.yearQuarter = yearQuarter;
    }

    getPage(): Pages {
        return Pages.ENCODE_CASES;
    }

    public static fromObj(obj: any): FetchPageToolbarDataEncodeCasesResponseDto {
        if (!obj) {
            return null;
        }
        return new FetchPageToolbarDataEncodeCasesResponseDto(obj.yearQuarter);
    }
}

export class FetchPageToolbarDataFormDResponseDto {
    years: FilterOption[];
    competentAuthorities: FilterOption[];

    constructor(years: FilterOption[], competentAuthorities: FilterOption[]) {
        this.years = years;
        this.competentAuthorities = competentAuthorities;
    }

    getPage(): Pages {
        return Pages.FORM_D;
    }

    public static fromObj(obj: any): FetchPageToolbarDataFormDResponseDto {
        if (!obj) {
            return null;
        }
        return new FetchPageToolbarDataFormDResponseDto(obj.years, obj.competentAuthorities);
    }
}

export class FetchPageToolbarDataAuthoritiesResponseDto {
    years: FilterOption[];
    records: FilterOption[];

    constructor(years: FilterOption[], records: FilterOption[]) {
        this.years = years;
        this.records = records;
    }

    getPage(): Pages {
        return Pages.AUTHORITIES;
    }

    public static fromObj(obj: any): FetchPageToolbarDataAuthoritiesResponseDto {
        if (!obj) {
            return null;
        }
        return new FetchPageToolbarDataAuthoritiesResponseDto(obj.years,
            [new FilterOption(RecordsValues.VALID, RecordsValues.VALID, true),
            new FilterOption(RecordsValues.INVALID, RecordsValues.INVALID, false)]);
    }
}

export class FetchPageToolbarDataRegistrationsLicensesResponseDto {
    records: FilterOption[];

    constructor(records: FilterOption[]) {
        this.records = records;
    }

    getPage(): Pages {
        return Pages.REGISTRATIONS_LICENCES;
    }
}

export class FetchPageToolbarDataReportsResponseDto {
    years: FilterOption[];

    constructor(years: FilterOption[]) {
        this.years = years;
    }

    getPage(): Pages {
        return Pages.REPORTS;
    }

    public static fromObj(obj: any): FetchPageToolbarDataReportsResponseDto {
        if (!obj) {
            return null;
        }
        return new FetchPageToolbarDataReportsResponseDto(obj.years);
    }
}
export class FilterOption {
    value: string;
    label: string;
    selected: boolean;

    constructor(value: string, label: string, selected: boolean) {
        this.value = value;
        this.selected = selected;
        this.label = label;
    }

    public static fromObj(obj: any): FilterOption {
        if (!obj) {
            return null;
        }
        return new FilterOption(obj.value, obj.label, obj.selected);
    }
}

export function isFetchPageToolbarDataEncodeCasesResponseDto(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataEncodeCasesResponseDto {
    return fetchPageToolbarDataResponseDto.getPage() === Pages.ENCODE_CASES;
}

export function isFetchPageToolbarDataFormDResponseDto(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataFormDResponseDto {
    return fetchPageToolbarDataResponseDto.getPage() === Pages.FORM_D;
}

export function isFetchPageToolbarDataAuthoritiesResponseDto(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataAuthoritiesResponseDto {
    return fetchPageToolbarDataResponseDto.getPage() === Pages.AUTHORITIES;
}

export function isFetchPageToolbarDataRegistrationsLicensesResponseDto(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataRegistrationsLicensesResponseDto {
    return fetchPageToolbarDataResponseDto.getPage() === Pages.REGISTRATIONS_LICENCES;
}

export function isFetchPageToolbarDataReportsResponseDto(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataReportsResponseDto {
    return fetchPageToolbarDataResponseDto.getPage() === Pages.REPORTS;
}

export function hasFetchPageToolbarDataResponseDtoYearQuarterField(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataEncodeCasesResponseDto {
    if (isFetchPageToolbarDataEncodeCasesResponseDto(fetchPageToolbarDataResponseDto)) {
            // fetchPageToolbarDataResponseDto.yearQuarter
        return true;
    }
    return false;
}

export function hasFetchPageToolbarDataResponseDtoYearsField(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataFormDResponseDto | FetchPageToolbarDataAuthoritiesResponseDto | FetchPageToolbarDataReportsResponseDto {
    if (isFetchPageToolbarDataFormDResponseDto(fetchPageToolbarDataResponseDto)
        || isFetchPageToolbarDataAuthoritiesResponseDto(fetchPageToolbarDataResponseDto) || isFetchPageToolbarDataReportsResponseDto(fetchPageToolbarDataResponseDto)) {
            // fetchPageToolbarDataResponseDto.years
        return true;
    }
    return false;
}

export function hasFetchPageToolbarDataResponseDtoCompetentAuthoritiesField(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataFormDResponseDto {
    if (isFetchPageToolbarDataFormDResponseDto(fetchPageToolbarDataResponseDto)) {
        // fetchPageToolbarDataResponseDto.competentAuthorities
        return true;
    }
    return false;
}

export function hasFetchPageToolbarDataResponseDtoRecordsField(fetchPageToolbarDataResponseDto: FetchPageToolbarDataResponseDto): fetchPageToolbarDataResponseDto is FetchPageToolbarDataAuthoritiesResponseDto | FetchPageToolbarDataRegistrationsLicensesResponseDto {
    if (isFetchPageToolbarDataAuthoritiesResponseDto(fetchPageToolbarDataResponseDto)
        || isFetchPageToolbarDataRegistrationsLicensesResponseDto(fetchPageToolbarDataResponseDto)) {
        // fetchPageToolbarDataResponseDto.records
        return true;
    }
    return false;
}
