import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { FileResponseDto } from "../dtos/file-response-dto";
import { ADMINISTRATION_API_URL } from "src/environments/environment";
import { FetchUserGuideRequestDto } from "../dtos/fetch-user-guide-dto";

@Injectable({
    providedIn: "root",
})
export class BackendAssetsService {
    constructor(private http: HttpClient) {}

    fetchUserGuide(
        fetchUserGuideRequestDto: FetchUserGuideRequestDto
    ): Observable<FileResponseDto> {
        const fetchUserGuidetUrl = `${ADMINISTRATION_API_URL}/backend-assets/fetch-user-guide`;
        return this.http
            .get(fetchUserGuidetUrl, {
                params: {
                    ...fetchUserGuideRequestDto,
                },
            })
            .pipe(map((result) => FileResponseDto.fromObj(result)));
    }
}
