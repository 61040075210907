import { IResponseErrorDto } from "./i-response-error-dto";

export class FetchUserGuideRequestDto {
    userGuide: UserGuideEnum;

    constructor(obj: { userGuide: UserGuideEnum }) {
        this.userGuide = obj.userGuide;
    }
}

export class FetchUserGuideResponseErrorDto implements IResponseErrorDto {
    hasGenericUnprocessableEntityError = false;

    static fromObj(obj: any): FetchUserGuideResponseErrorDto | null {
        if (!obj) {
            return null;
        }
        const fetchUserGuideResponseErrorDto = new FetchUserGuideResponseErrorDto();
        fetchUserGuideResponseErrorDto.hasGenericUnprocessableEntityError = obj.hasGenericUnprocessableEntityError;
        return fetchUserGuideResponseErrorDto;
    }
}

export enum UserGuideEnum {
    DPR_IT_SYSTEM_EC_USER_GUIDE = 'DPR_IT_SYSTEM_EC_USER_GUIDE',
    DPR_IT_SYSTEM_USER_GUIDE = 'DPR_IT_SYSTEM_USER_GUIDE'
}

export function userGuideEnumFromUnkown(value: string): UserGuideEnum | null {
    if (value == null) {
        return null;
    }
    switch (value) {
        case UserGuideEnum.DPR_IT_SYSTEM_EC_USER_GUIDE:
            return UserGuideEnum.DPR_IT_SYSTEM_EC_USER_GUIDE;
        case UserGuideEnum.DPR_IT_SYSTEM_USER_GUIDE:
            return UserGuideEnum.DPR_IT_SYSTEM_USER_GUIDE;
        default:
            return null;
    }
}

export function userGuideEnumLabels(): Record<UserGuideEnum, string> {
    return {
        [UserGuideEnum.DPR_IT_SYSTEM_EC_USER_GUIDE]: "EC User's Guide",
        [UserGuideEnum.DPR_IT_SYSTEM_USER_GUIDE]: "User's Guide"
    }
}