import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {

  transform(values: string[] | Set<string>, sep = ','): string {
    return values ? Array.from(values).join(sep) : '';
  }

}
