<form *ngIf="advancedFiltersForm" [formGroup]="advancedFiltersForm" (keydown.enter)="$event.preventDefault()">
    <div class="container">
        <ng-container formArrayName="advancedFilterRows">
            <ng-container *ngFor="let rowForm of advancedFilterRows.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <div class="row eui-u-mt-s">
<!--                        Information type-->
                        <div class="col-md-3">
                            <label euiLabel for="column"><strong>{{'dg.grow.advanced.filter.info.type.label' | translate}}</strong></label>
                            <select euiSelect name="column" id="column" formControlName="column" (change)="columnChanged(i)">
                                <option *ngFor="let column of columns" [ngValue]="column.value">{{column.label | translate}}</option>
                            </select>
                        </div>
<!--                        Contains-->
                        <div class="col-md-3">
                            <label euiLabel for="filterType"><strong>{{'dg.grow.advanced.filter.contains.label' | translate}}</strong></label>
                            <select euiSelect name="filterType" id="filterType" formControlName="filterType">
                                <ng-container *ngIf="rowForm?.get('columnType')?.value === 'numeric'">
                                    <option *ngFor="let filterType of filterTypesNumeric" [ngValue]="filterType.value">{{filterType.label  | translate}}</option>
                                </ng-container>
                                <ng-container *ngIf="rowForm?.get('columnType')?.value === 'non-numeric'">
                                    <option *ngFor="let filterType of filterTypesNonNumeric" [ngValue]="filterType.value">{{filterType.label  | translate}}</option>
                                </ng-container>
                                <ng-container *ngIf="rowForm?.get('columnType')?.value === 'categorical'">
                                    <option *ngFor="let filterType of filterTypesCategorical" [ngValue]="filterType.value">{{filterType.label  | translate}}</option>
                                </ng-container>
                            </select>
                        </div>
<!--                        Keyword-->
                        <div class="col-md-4">
                            <label euiLabel for="user_input"><strong>{{'dg.grow.advanced.filter.keyword.label' | translate}}</strong></label>
                            <ng-container *ngIf="rowForm?.get('keywordType')?.value === 'text'">
                                <input euiInputText id="user_input" formControlName="userInput" />
                            </ng-container>
                            <ng-container *ngIf="rowForm?.get('keywordType')?.value === KeywordType.YESNO || rowForm?.get('keywordType')?.value === KeywordType.STATUS || rowForm?.get('keywordType')?.value === KeywordType.COMPLETED || rowForm?.get('keywordType')?.value === KeywordType.TYPE_OF_AUTHORITY">
                                <select euiSelect name="user_input" id="user_input" formControlName="userInput">
                                    <ng-container *ngIf="rowForm?.get('keywordType')?.value === KeywordType.YESNO">
                                        <option *ngFor="let keywordType of keywordTypesYesNo" [ngValue]="keywordType.value">{{keywordType.label  | translate}}</option>
                                    </ng-container>
                                    <ng-container *ngIf="rowForm?.get('keywordType')?.value === KeywordType.STATUS">
                                        <option *ngFor="let keywordType of keywordTypesStatus" [ngValue]="keywordType.value">{{keywordType.label  | translate}}</option>
                                    </ng-container>
                                    <ng-container *ngIf="rowForm?.get('keywordType')?.value === KeywordType.COMPLETED">
                                        <option *ngFor="let keywordType of keywordTypesCompleted" [ngValue]="keywordType.value">{{keywordType.label  | translate}}</option>
                                    </ng-container>
                                    <ng-container *ngIf="rowForm?.get('keywordType')?.value === KeywordType.TYPE_OF_AUTHORITY">
                                        <option *ngFor="let keywordType of keywordTypesTypeOfAuthority" [ngValue]="keywordType.value">{{keywordType.label  | translate}}</option>
                                    </ng-container>
                                </select>
                            </ng-container>
                        </div>
<!--                        Plus/Minus-->
                        <div class="col-md-2 eui-u-mt-xl">
                            <button euiButton euiIconButton euiPrimary euiSizeS
                                    euiTooltip="{{ 'dg.grow.advanced.search.dialog.body.add' | translate }}" (click)="addRow()" class="eui-u-mr-s">
                                <eui-icon-svg icon="eui-add"></eui-icon-svg>
                            </button>
                            <button euiButton euiIconButton euiPrimary euiSizeS
                                    euiTooltip="{{ 'dg.grow.advanced.search.dialog.body.remove' | translate }}" (click)="removeRow(i)">
                                <eui-icon-svg icon="eui-ecl-minus"></eui-icon-svg>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
        <hr >
        <div class="row eui-u-mt-m">
            <div class="col-md-3">
                <label euiLabel for="rules"><strong>{{ 'dg.grow.advanced.filter.rules.label' | translate }}</strong></label>
                <select euiSelect name="rules" id="rules" formControlName="chosenRule">
                    <option *ngFor="let rules of rules" [ngValue]="rules?.value">{{rules?.label | translate}}</option>
                </select>
            </div>
        </div>
    </div>
</form>
