import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'presentNegativeNumberAsDash',
})
export class PresentNegativeNumberAsDashPipe implements PipeTransform {
    transform(value: any): string | number {
        if (typeof value === 'number') {
            return value < 0 ? '-' : value;
        }
        return value;
    }
}
