import {AdvancedFilterColumnOption, AdvancedFilterOption, AdvancedFilterSelectValuesOption, AdvancedFilterTypesOption} from './advanced-filter.model';

export class FetchAdvancedFilterOptions{
    columns: AdvancedFilterColumnOption[];
    filterTypes: AdvancedFilterTypesOption;
    rules: AdvancedFilterOption[];
    keywordValues: AdvancedFilterSelectValuesOption;

    constructor(columns: AdvancedFilterColumnOption[], filterTypes: AdvancedFilterTypesOption,
        rules: AdvancedFilterOption[], keywordValues: AdvancedFilterSelectValuesOption) {
        this.columns = columns;
        this.filterTypes = filterTypes;
        this.rules = rules;
        this.keywordValues = keywordValues;
    }

    public static fromObj(obj: any): FetchAdvancedFilterOptions {
        if (!obj) {
            return null;
        }

        return new FetchAdvancedFilterOptions(
            AdvancedFilterColumnOption.listFromObjList(obj.columns),
            AdvancedFilterTypesOption.fromObj(obj.filterTypes),
            AdvancedFilterOption.listFromObjList(obj.rules),
            AdvancedFilterSelectValuesOption.fromObj(obj.keywordValues),
        );
    }
}
