import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserService, UserState, UxAppShellService, UxLink} from '@eui/core';
import {EuiDialogComponent} from '@eui/components/eui-dialog';
import {Title} from "@angular/platform-browser";
import {Observable, Subscription} from 'rxjs';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {PermissionCodes} from './shared/auth/auth-path-permission-mapping';
import {CustomUserDetails} from './shared/custom-user-details';
import {AuthUtils} from './shared/services/auth-utils.service';
import {CommonUtilsService} from './shared/services/common-utils.service';
import {UpdateLastLogonResponseErrorDto} from './shared/dtos/update-last-logon.dto';
import {HEADER_ENVIRONMENT, LINKS, SHOW_HEADER_ENVIRONMENT} from '../environments/environment';
import {AuthConstants} from './shared/auth/auth-constants';
import {AcceptPrivacyStatementResponseErrorDto} from './shared/dtos/accept-privacy-statement.dto';
import {DeclinePrivacyStatementResponseErrorDto} from './shared/dtos/decline-privacy-statement.dto';
import {FetchUserGuideRequestDto, FetchUserGuideResponseErrorDto, UserGuideEnum, userGuideEnumLabels} from './shared/dtos/fetch-user-guide-dto';
import {BackendAssetsService} from './shared/services/backend-assets.service';
import {FileResponseDto} from './shared/dtos/file-response-dto';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    readonly UserGuideEnum: typeof UserGuideEnum = UserGuideEnum;
    readonly LINKS = LINKS;
    readonly PRIVACY_STATEMENT_DENIED_STORAGE_KEY = 'PRIVACY_STATEMENT_DENIED';

    @ViewChild('privacyStatementDialog') privacyStatementDialog: EuiDialogComponent;

    userInfos: CustomUserDetails;

    hasMenuCasesActive = false;
    hasMenuAllCasesActive = false;
    hasMenuAdministrationActive = false;
    hasMenuAdministrationUsersActive = false;
    hasMenuAdministrationRolesActive = false;
    hasMenuAdministrationPermissionsActive = false;
    hasMenuAuthoritiesActive = false;
    hasMenuSubstancesActive = false;
    hasMenuUploadsActive = false;
    hasMenuFormDActive = false;
    hasMenuOperatorActive = false;
    hasMenuReportsActive = false;
    hasUserGuideEc = false;
    hasUserGuideCa = false;

    // Observe state changes
    userState: Observable<UserState>;
    // an array to keep all subscriptions and easily unsubscribe
    subs: Subscription[] = [];

    applicationLoaded = false;
    isAuthenticated = false;
    AUTH_CONSTANT_INVALID: string = AuthConstants.invalid;

    menuItems: UxLink[] = [];
    helpItems: UxLink[] = [];

    isWaitingPrivacyStatementDialogAction = false;

    readonly HEADER_ENVIRONMENT = HEADER_ENVIRONMENT;
    readonly SHOW_HEADER_ENVIRONMENT = SHOW_HEADER_ENVIRONMENT;

    constructor(
        private translateService: TranslateService,
        private authUtils: AuthUtils,
        private commonUtilsService: CommonUtilsService,
        private userService: UserService,
        private asService: UxAppShellService,
        private backendAssetsService: BackendAssetsService,
        private titleService: Title,
    ) {

    }

    ngOnInit() {
        // Set title from translations.
        this.titleService.setTitle(this.translateService.instant('dg.grow.app.title'));

        // Consider the user not authenticated at this point.
        this.isAuthenticated = false;
        this.subs.push(this.authUtils.isAuthFinished$.subscribe((isAuthFinished => {
            if (isAuthFinished) {
                // Authentication check is finished

                this.subs.push(this.userService.getState().subscribe((user => {
                    this.userInfos = AuthUtils.convertUserStateToCustomUserDetails(user);
                    if (this.userInfos.userPermissions) {
                        this.hasMenuCasesActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_CASES_ACTIVE);
                        this.hasMenuAllCasesActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_ALL_CASES_ACTIVE);
                        this.hasMenuAdministrationActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_ADMIN);
                        this.hasMenuAdministrationUsersActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_SEC_USR);
                        this.hasMenuAdministrationRolesActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_SEC_ROL);
                        this.hasMenuAdministrationPermissionsActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_SEC_PERM);
                        this.hasMenuAuthoritiesActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_LISTS_CA);
                        this.hasMenuSubstancesActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_LISTS_SUB);
                        this.hasMenuFormDActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_FORMD);
                        this.hasMenuOperatorActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_OPR);
                        this.hasMenuUploadsActive = this.userInfos.userPermissions.has(PermissionCodes.VIEW_UPLOADS_ALL) || this.userInfos.userPermissions.has(PermissionCodes.VIEW_UPLOADS_OWNCOUNTRY);
                        this.hasMenuReportsActive = this.userInfos.userPermissions.has(PermissionCodes.MENU_REPORTS);
                        this.hasUserGuideEc = this.userInfos.userPermissions.has(PermissionCodes.USER_GUIDE_EC);
                        this.hasUserGuideCa = this.userInfos.userPermissions.has(PermissionCodes.USER_GUIDE_CA);
                    }

                    this.intializeAppLayout();
                    // Consider the application as loaded.

                    // we listen to language changes in order to reinitialize menus
                    this.subs.push(this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
                        // Upon language change, reload menuItems/sidebarItems and notificationItems, for the new translations to apply.
                        this.intializeAppLayout();
                        // Recreate the component to ensure all rest components are refreshed for the new translations. For example, breadcrumbs.
                        this.applicationLoaded = false;
                        // Set timeout 0 ensures the component will have been destroyed first before setting again to true, so that the component will be recreated.
                        setTimeout(() => {
                            this.applicationLoaded = true;
                            }, 0);
                        }));

                    if (this.userInfos?.username && this.userInfos?.username !== AuthConstants.invalid) {
                        // user is authenticated
                        this.isAuthenticated = true;
                        this.subs.push(this.authUtils.updateLastLogon().subscribe({
                            next: (result) => {
                                this.applicationLoaded = true;
                                setTimeout(() => {
                                    this.openpPivacyStatementDialogIfNotAccepted();
                                });
                            },
                            error: (error) => {
                                this.commonUtilsService.getResponseErrorAndDisplayGenericErrors(error,
                                    UpdateLastLogonResponseErrorDto.fromObj
                                );
                            }
                        }));
                    } else {
                        // user is anonymous or invalid.
                        this.isAuthenticated = false;
                        this.applicationLoaded = true;

                        const privacyStatementDeniedLocalStorageItem = window.localStorage.getItem(this.PRIVACY_STATEMENT_DENIED_STORAGE_KEY);
                        if (privacyStatementDeniedLocalStorageItem) {
                            window.localStorage.removeItem(this.PRIVACY_STATEMENT_DENIED_STORAGE_KEY);
                            this.asService.growl({
                                severity: 'warning',
                                summary: this.translateService.instant('dg.grow.privacy.statement.declined.warning.summary'),
                                detail:  this.translateService.instant('dg.grow.privacy.statement.declined.warning.detail')
                            }, false, false, 4000, 'top-center');
                        }
                    }
                })));

            }
        })));
    }

    /**
     * Initializes app layout
     */
    private intializeAppLayout() {
        // Initialize menuItems/sidebarItems.
        this.initializeMenuItems();
        this.initializeHelpItems();
    }

    getProfileHeaderSubtitleInfo(): string {
        const roles = this.userInfos?.userRolesNames?.length ? this.userInfos.userRolesNames.join(', ') : '';
        const country = this.userInfos?.caCountry || '';
        if (roles && country) {
            return `${roles} - ${country}`;
        } else if (roles) {
            return roles;
        } else if (country) {
            return country;
        } else {
            return '';
        }
    }

    ngOnDestroy() {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    private initializeCasesMenuItems() {
        if (this.hasMenuCasesActive || this.hasMenuAllCasesActive) {
            const casesMenuChildrenItems: UxLink[] = [];
            if (this.hasMenuCasesActive) {
                casesMenuChildrenItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.encode.cases'), url: 'encode-cases' }));
            }
            if (this.hasMenuAllCasesActive) {
                casesMenuChildrenItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.all.eu.cases'), url: 'all-eu-cases' }));
            }
            this.menuItems.push(new UxLink(
                {
                    label: this.translateService.instant('dg.grow.menu.item.cases'),
                    children: casesMenuChildrenItems
                }
            ));
        }
    }

    private initializeAdministrationMenuItems() {
        if (this.hasMenuAdministrationActive) {
            const administrationMenuChildItems: UxLink[] = [];
            if (this.hasMenuAdministrationUsersActive) {
                administrationMenuChildItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.administration.users'), url: 'administration/users' }));
            }
            if (this.hasMenuAdministrationRolesActive) {
                administrationMenuChildItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.administration.roles'), url: 'administration/roles' }));
            }
            if(this.hasMenuAdministrationPermissionsActive) {
                administrationMenuChildItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.administration.permissions'), url: 'administration/permissions' }));
            }
            this.menuItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.administration'), children: administrationMenuChildItems }));
        }
    }

    private initializeListMenuItems() {
        if (this.hasMenuAuthoritiesActive || this.hasMenuSubstancesActive || this.hasMenuUploadsActive) {
            const listMenuChildItems: UxLink[] = [];
            if (this.hasMenuAuthoritiesActive) {
                listMenuChildItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.authorities'), url: 'authorities' }));
            }
            if (this.hasMenuSubstancesActive) {
                listMenuChildItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.substances'), url: 'substances' }));
            }
            if (this.hasMenuUploadsActive){
                listMenuChildItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.uploads'), url: 'uploads' }));
            }
            this.menuItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.lists'), children: listMenuChildItems}));
        }
    }

    private initializeOperatorsMenuItems() {
        if (this.hasMenuOperatorActive) {
            this.menuItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.registrations.licences'), url: 'registrations-licences' }));
        }
    }

    /**
     * Initialize menuItems/sidebarItems.
     */
    private initializeMenuItems() {
        this.menuItems = [];
        this.menuItems.push(new UxLink({ label: '', iconClass:'eui-icon-home',  url: 'home'}));

        this.initializeCasesMenuItems();
        if(this.hasMenuFormDActive){
            this.menuItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.form.d'), url: 'form-d' }));
        }
        this.initializeOperatorsMenuItems();
        // Reports
        if(this.hasMenuReportsActive){
            this.menuItems.push(new UxLink({ label: this.translateService.instant('dg.grow.menu.item.reports'), url: 'reports' }));
        }
        this.initializeListMenuItems();
        this.initializeAdministrationMenuItems();
    }

    /**
     * Create Help Menu List items conditionally based on permissions
     */
    private initializeHelpItems() {
        this.helpItems = [];
        const labels = userGuideEnumLabels();
        this.addUserGuideConditionaly(labels, UserGuideEnum.DPR_IT_SYSTEM_EC_USER_GUIDE, this.hasUserGuideEc);
        this.addUserGuideConditionaly(labels, UserGuideEnum.DPR_IT_SYSTEM_USER_GUIDE, this.hasUserGuideEc || this.hasUserGuideCa);
    }

    private addUserGuideConditionaly(labels: Record<UserGuideEnum, string>, userGuide: UserGuideEnum, condition: boolean) {
        if (condition) {
            this.helpItems.push(new UxLink({ id: userGuide, label: labels[userGuide]}));
        }
    }

    userGuideClicked(userGuide: UserGuideEnum) {
        this.backendAssetsService.fetchUserGuide(new FetchUserGuideRequestDto({ userGuide: userGuide}))
            .subscribe({
                next: (result: FileResponseDto) => {
                    if (result) {
                        this.commonUtilsService.streamFileInTab(result.file, result.mimeType, result.fileName);
                    }
                }, error: (err) => {
                    this.commonUtilsService.getResponseErrorAndDisplayGenericErrors(err, FetchUserGuideResponseErrorDto.fromObj)
                }
            })
    }

    async logout(event: any) {
        await this.authUtils.logout();
    }


    private openpPivacyStatementDialogIfNotAccepted() {
        if (this.userInfos.privacyStatementAccepted === null || this.userInfos.privacyStatementAccepted === false) {
            this.privacyStatementDialog.openDialog();
        }
    }


    public privacyStatementClicked() {
        this.privacyStatementDialog.openDialog();
    }

    public privacyStatementPrintClicked(event) {
        window.document.body.classList.add('print-privacy-statement');
        window.print()
        window.document.body.classList.remove('print-privacy-statement');
    }

    public async privacyStatementDeclineClicked(event) {
        if (this.userInfos.privacyStatementAccepted === false) {
            window.localStorage.setItem(this.PRIVACY_STATEMENT_DENIED_STORAGE_KEY, 'true');
            await this.authUtils.logout()
        } else {
            this.isWaitingPrivacyStatementDialogAction = true;
            this.subs.push(this.authUtils.declinePrivacyStatement().subscribe({
                next: async (result) => {
                    window.localStorage.setItem(this.PRIVACY_STATEMENT_DENIED_STORAGE_KEY, 'true');
                    await this.authUtils.logout();
                    this.isWaitingPrivacyStatementDialogAction = false;
                },
                error: (error) => {
                    this.commonUtilsService.getResponseErrorAndDisplayGenericErrors(error,
                        DeclinePrivacyStatementResponseErrorDto.fromObj
                    );
                    this.isWaitingPrivacyStatementDialogAction = false;
                }
            }));
        }
    }

    public privacyStatementAcceptClicked(event) {this.isWaitingPrivacyStatementDialogAction = true;
        this.subs.push(this.authUtils.acceptPrivacyStatement().subscribe({
            next: (result) => {
                window.location.reload();
                this.isWaitingPrivacyStatementDialogAction = false;
            },
            error: (error) => {
                this.isWaitingPrivacyStatementDialogAction = false;
                this.commonUtilsService.getResponseErrorAndDisplayGenericErrors(error,
                    AcceptPrivacyStatementResponseErrorDto.fromObj
                );
            }
        }));

    }
}
