import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RedirectComponent} from './shared/components/redirect/redirect.component';
import {AuthorizationGuardService} from './shared/services/authorization-guard.service';
import {RedirectGuardService} from './shared/services/redirect-guard.service';

const routes: Routes = [
    { path: '', pathMatch: 'full', component: RedirectComponent, canActivate: [RedirectGuardService] },
    { path: 'home', loadChildren: () => import('./features/home/home.module').then(m => m.Module) },
    { path: 'encode-cases', loadChildren: () => import('./features/cases/encode-cases/encode-cases.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'all-eu-cases', loadChildren: () => import('./features/cases/all-eu-cases/all-eu-cases.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'form-d', loadChildren: () => import('./features/form-d/form-d.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'reports', loadChildren: () => import('./features/reports/reports.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'registrations-licences', loadChildren: () => import('./features/registrations-licences/registrations-licences.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'authorities', loadChildren: () => import('./features/authorities/authorities.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'substances', loadChildren: () => import('./features/substances/substances.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'uploads', loadChildren: () => import('./features/uploads/uploads.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    { path: 'administration', children: [
            { path: 'users', loadChildren: () => import('./features/administration/users/users.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
            { path: 'roles', loadChildren: () => import('./features/administration/roles/roles.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
            { path: 'permissions', loadChildren: () => import('./features/administration/permissions/permissions.module').then(m => m.Module), canActivate: [AuthorizationGuardService] },
    ]},
    {
        path: 'unauthorized',
        pathMatch: 'full',
        loadChildren: () => import('./shared/page-not-authorized/page-not-authorized.module').then(m => m.PageNotAuthorizedModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('./shared/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: true })
    ],
})
export class AppRoutingModule {}
