import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserService} from '@eui/core';
import {AuthUtils} from './auth-utils.service';
import {Pages} from '../enums/enums';

@Injectable({
    providedIn: 'root',
})
export class RedirectGuardService implements CanActivate {
    constructor(
        private readonly _router: Router,
        private userService: UserService,
        private authUtils: AuthUtils,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            this.authUtils.isAuthFinished$.subscribe(isAuthFinished => {
                if (isAuthFinished) {
                    const key = 'sign_in_navigation_url';
                    const navigationUrl = localStorage.getItem(key);
                    if (navigationUrl) {
                        localStorage.removeItem(key);
                        this._router.navigateByUrl(navigationUrl);
                    } else {
                        this._router.navigate([Pages.HOME]);
                    }
                }
            });
        });
    }

}
