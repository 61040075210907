
export enum PermissionCodes {
    // Cases.
    CASE_VIEW_ACTIVE = 'CASE_VIEW_ACTIVE',
    CASE_ENCODING_CONFIRM = 'CASE_ENCODING_CONFIRM',
    CASE_ENCODING_UNMARK = 'CASE_ENCODING_UNMARK',
    CASE_ADD = 'CASE_ADD',
    CASE_EDIT = 'CASE_EDIT',
    CASE_DELETE = 'CASE_DELETE',
    CASE_VIEW_ACTIVE_ALL = 'CASE_VIEW_ACTIVE_ALL',
    CASE_EXPORT_ALL = 'CASE_EXPORT_ALL',
    MENU_CASES_ACTIVE = 'MENU_CASES_ACTIVE',
    MENU_ALL_CASES_ACTIVE = 'MENU_ALL_CASES_ACTIVE',

    // Form D
    MENU_FORMD = 'MENU_FORMD',
    FORMD_MARK_COMPLETE = 'FORMD_MARK_COMPLETE',
    FORMD_UNMARK_COMPLETE = 'FORMD_UNMARK_COMPLETE',
    FORMD_VIEW_ALLCOUNTRIES = 'FORMD_VIEW_ALLCOUNTRIES',
    FORMD_VIEW_OWNCOUNTRY = 'FORMD_VIEW_OWNCOUNTRY',
    FORMD_ADD = 'FORMD_ADD',
    FORMD_EDIT = 'FORMD_EDIT',

    // Competent Authorities
    CA_VIEW = 'CA_VIEW',
    CA_VIEW_DETAILS = 'CA_VIEW_DETAILS',
    CA_ADD = 'CA_ADD',
    CA_EDIT_ALL = 'CA_EDIT_ALL',
    CA_DELETE = 'CA_DELETE',
    CA_EXPORT = 'CA_EXPORT',
    CA_EDIT_OWN = 'CA_EDIT_OWN',
    MENU_LISTS_CA = 'MENU_LISTS_CA',
    CA_VIEW_USERS_ALL = 'CA_VIEW_USERS_ALL',
    CA_VIEW_USERS_OWN = 'CA_VIEW_USERS_OWN',

    // SUBSTANCES
    MENU_LISTS_SUB = 'MENU_LISTS_SUB',
    SUB_VIEW = 'SUB_VIEW',
    SUB_ADD_ALLTYPES = 'SUB_ADD_ALLTYPES',
    SUB_EXPORT = 'SUB_EXPORT',
    SUB_DELETE_ALLTYPES = 'SUB_DELETE_ALLTYPES',
    SUB_EDIT_ALLTYPES = 'SUB_EDIT_ALLTYPES',
    SUB_MERGE = 'SUB_MERGE',
    SUB_ADD_OTHER = 'SUB_ADD_OTHER',
    SUB_DELETE_OTHER = 'SUB_DELETE_OTHER',
    SUB_EDIT_OTHER = 'SUB_EDIT_OTHER',

    // ADMIN
    MENU_ADMIN = "MENU_ADMIN",

    // USERS
    SEC_USR_VIEW = "SEC_USR_VIEW",
    SEC_USR_EDIT = "SEC_USR_EDIT",
    SEC_USR_DELETE = "SEC_USR_DELETE",
    SEC_USR_ADD = 'SEC_USR_ADD',
    MENU_SEC_USR = "MENU_SEC_USR",

    // ROLES
    SEC_ROL_VIEW = "SEC_ROL_VIEW",
    SEC_ROL_ADD	= "SEC_ROL_ADD",
    SEC_ROL_EDIT = "SEC_ROL_EDIT",
    SEC_ROL_DELETE = "SEC_ROL_DELETE",
    MENU_SEC_ROL = "MENU_SEC_ROL",

    // PERMISSIONS
    SEC_PERM_VIEW = "SEC_PERM_VIEW",
    SEC_PERM_ADD = "SEC_PERM_ADD",
    SEC_PERM_EDIT = "SEC_PERM_EDIT",
    SEC_PERM_DELETE = "SEC_PERM_DELETE",
    MENU_SEC_PERM = "MENU_SEC_PERM",

    // OPERATORS
    OPR_ADD_ALL = "OPR_ADD_ALL",
    OPR_ADD_OWN = "OPR_ADD_OWN",
    OPR_VIEW_LIST_ALL = "OPR_VIEW_LIST_ALL",
    OPR_VIEW_LIST_OWN = "OPR_VIEW_LIST_OWN",
    OPR_EDIT_ALL = "OPR_EDIT_ALL",
    OPR_EDIT_OWN = "OPR_EDIT_OWN",
    OPR_DELETE_ALL = "OPR_DELETE_ALL",
    OPR_DELETE_OWN = "OPR_DELETE_OWN",
    OPR_BULKUPLOAD_ADD_ALL = "OPR_BULKUPLOAD_ADD_ALL",
    OPR_BULKUPLOAD_ADD_OWN = "OPR_BULKUPLOAD_ADD_OWN",
    OPR_BULKUPLOAD_EDIT_ALL = "OPR_BULKUPLOAD_EDIT_ALL",
    OPR_BULKUPLOAD_EDIT_OWN = "OPR_BULKUPLOAD_EDIT_OWN",
    OPR_EXPORT_ALL = "OPR_EXPORT_ALL",
    OPR_EXPORT_OWN = "OPR_EXPORT_OWN",
    MENU_OPR = "MENU_OPR",

    // UPLOADS
    VIEW_UPLOADS_ALL = "VIEW_UPLOADS_ALL",
    VIEW_UPLOADS_OWNCOUNTRY = "VIEW_UPLOADS_OWNCOUNTRY",

    // REPORTS
    MENU_REPORTS = "MENU_REPORTS",
    REPORTS_EXPORT = "REPORTS_EXPORT",

    // USER GUIDES
    USER_GUIDE_EC = 'USER_GUIDE_EC',
    USER_GUIDE_CA = 'USER_GUIDE_CA',
}

export interface PathPermissionMapping {
    [key: string]: Set<PermissionCodes>;
}

export const PATH_PERMISSION_MAPPING: PathPermissionMapping = {
    '/encode-cases': new Set<PermissionCodes>([PermissionCodes.MENU_CASES_ACTIVE]),
    '/all-eu-cases': new Set<PermissionCodes>([PermissionCodes.MENU_ALL_CASES_ACTIVE]),
    '/form-d': new Set<PermissionCodes>([PermissionCodes.MENU_FORMD]),
    '/administration/users': new Set<PermissionCodes>([PermissionCodes.MENU_SEC_USR]),
    '/administration/roles': new Set<PermissionCodes>([PermissionCodes.MENU_SEC_ROL]),
    '/administration/permissions': new Set<PermissionCodes>([PermissionCodes.MENU_SEC_PERM]),
    '/authorities': new Set<PermissionCodes>([PermissionCodes.MENU_LISTS_CA]),
    '/substances': new Set<PermissionCodes>([PermissionCodes.MENU_LISTS_SUB]),
    '/registrations-licences': new Set<PermissionCodes>([PermissionCodes.MENU_OPR]),
    '/uploads': new Set<PermissionCodes>([PermissionCodes.VIEW_UPLOADS_ALL, PermissionCodes.VIEW_UPLOADS_OWNCOUNTRY]),
    '/reports': new Set<PermissionCodes>([PermissionCodes.MENU_REPORTS]),
};
