export enum Pages {
    HOME = 'home',
    ENCODE_CASES = 'encodeCases',
    ALL_EU_CASES = 'allEuCases',
    FORM_D = 'formD',
    REGISTRATIONS_LICENCES = 'registrationsLicences',
    AUTHORITIES = 'authorities',
    SUBSTANCES = 'substances',
    NOTIFICATIONS = 'notifications',
    USERS = 'users',
    ROLES = 'roles',
    PERMISSIONS = 'permissions',
    UPLOADS = 'uploads',
    REPORTS = 'reports',
}

export enum Action {
    VIEW = 'view',
    EDIT = 'edit',
    ADD = 'add'
}

export enum FilterType {
    TEXT = 'text',
    NUMBER = 'number', // TODO: rename to INTEGER? All fetch-filter-options back-end endpoints from all back end modules must also reflect this change.
    DECIMAL = 'decimal',
    LIST = 'list'
}

export enum ColumnType {
    CATEGORICAL = 'categorical',
    NUMERIC = 'numeric',
    NON_NUMERIC = 'non-numeric'
}

export enum AdvancedFilterNumericType {
    EQUALS = 'equals',
    IS_GREATER_THAN = 'isGreaterThan',
    IS_LESS_THAN = 'isLessThan',
}

export enum AdvancedFilterNonNumericType {
    CONTAINS = 'contains',
    EQUALS = 'equals',
}

export enum AdvancedFilterCategoricalType {
    EQUALS = 'equals',
}

export enum AdvancedFilterRules {
    ALL_RULES = 'allRules',
    ANY_RULE = 'anyRule'
}

export enum EncodeCasesColumns {
    SUBSTANCE_NAME = 'substanceName',
    SUBSTANCE_TYPE = 'substanceType',
    TYPE_OF_EVENT = 'typeOfEvent',
    CASES = 'cases',
    KILOGRAM = 'kilogram',
    LITRE = 'litre',
    GRAM = 'gram',
    MILLILITRE = 'millilitre',
    INTRA_EXTRA_EU_TRADE = 'intraExtraEuTrade'
}

export enum AllCasesColumns {
    COUNTRY = 'country',
    COMPETENT_AUTHORITY = 'competentAuthority',
    YEAR_QUARTER = 'yearQuarter',
    SUBSTANCE_NAME = 'substanceName',
    SUBSTANCE_TYPE = 'substanceType',
    TYPE_OF_EVENT = 'typeOfEvent',
    CASES = 'cases',
    KILOGRAM = 'kilogram',
    LITRE = 'litre',
    GRAM = 'gram',
    MILLILITRE = 'millilitre',
    INTRA_EXTRA_EU_TRADE = 'intraExtraEuTrade',
    TYPE_OF_AUTHORITY = 'typeOfAuthority',
    CREATION_DATE = 'creationDate',
    LAST_UPDATE = 'lastUpdate',
}

export enum UsersColumns {
    LOGIN_NAME = 'loginName',
    NAME_SURNAME = 'nameSurname',
    EMAIL = 'email',
    CA_NAME = 'caName',
    COUNTRY = 'country',
    LAST_LOGON = 'lastLogon',
    STATUS = 'status'
}

export enum KeywordType {
    TEXT = 'text',
    YESNO = 'yesno',
    STATUS = 'status',
    COMPLETED = 'completed',
    TYPE_OF_AUTHORITY = 'typeOfAuthority',
}

export enum StatusColumnPossibleValues {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}

export enum YesNoPossibleValues {
    YES = 'Yes',
    NO = 'No'
}
/**
 * Complete Status Possible values.
 *
 * Values are used for FormDs and QuarterStats Complete Status.
 * - FormDs:
 *   - NA means
 *     - Not Started: FormD not yet exist
 *     - Not Available: FormD is not more available as Deleted or Obsolete.
 *   - NOT_COMPLETE means that FormD has been started and has some Active Encode Cases or Licit Trades/Uses linked.
 *   - COMPLETED means that FormD is marked as complete by an authorized user.
 * - QuarterStats
 *   - NA means no Encode Case reported yet or all related Encode Cases are deleted.
 *   - NOT_COMPLETE means that at least one Active Encode Case is linked.
 *   - COMPLETED means that Quarter Stat is marked as complete by an authorized user.
 */
export enum CompletedPossibleValues {
    COMPLETED = 'Completed',
    NOT_COMPLETED = 'Not completed',
    NA = 'N/A'
}

export enum RolesColumns {
    CODE = 'code',
    ROLE_NAME = 'roleName',
    NUM_OF_PERMISSIONS = 'numOfPermissions',
    NUM_OF_USERS = 'numOfUsers',
    STATUS = 'status'
}

export enum PermissionsColumns {
    CODE = 'code',
    NAME = 'name',
    NUMBER_OF_ROLES = 'numberOfRoles',
    STATUS = 'status'
}

export enum OrderValues {
    ASC = 'asc',
    DESC = 'desc'
}

export enum AuthoritiesColumns {
    REFERENCE = 'reference',
    COUNTRY = 'country',
    CA_NAME = 'caName',
    FORM_D_REPORTING = 'formDReporting',
    OPERATORS = 'operators',
    STATUS = 'status',
    Q1 = 'q1',
    Q2 = 'q2',
    Q3 = 'q3',
    Q4 = 'q4',
    FORM_D = 'formD'
}

export enum AllSubstancesColumns {
    SUBSTANCE_NAME = 'name',
    SUBSTANCE_TYPE = 'type',
    SUBSTANCE_CATEGORY = 'category',
    UN_TABLE = 'unTable',
    CN_DESIGNATION = 'cnDesignation',
    CN_CODE = 'cnCode',
    CAS_NO = 'casNo',
    UNIT = 'substanceUnit',
    STATUS = 'status',
    VALID_UNTIL = 'validUntil',
    LAST_UPDATED = 'lastUpdate'
}

export enum RecordsValues {
    VALID = 'Valid',
    INVALID = 'Invalid'
}

export enum AllUploadsColumns {
    COUNTRY = 'country',
    USERNAME = 'username',
    FILE_NAME = 'fileName',
    TYPE = 'type',
    DATE_OF_CREATION = 'dateOfCreation',
    NUMBER_OF_LINES = 'numberOfLines'
}

export enum OperatorsColumns {
    OPERATOR_NAME = 'operatorName',
    COUNTRY = 'country',
    CA = 'ca',
    REFERENCE = 'reference',
    STATUS = 'status',
}

export enum SearchAllRecordsTableColumns {
    OPERATOR_USER_NAME = 'operatorUserName',
    DELETED_OPERATOR = 'deletedOperator',
    STREET = 'street',
    ZIP_CODE = 'zipCode',
    TYPE = 'type',
    LIC_REG_NUMBER = 'licRegNumber',
    REG_LIC_STATUS = 'regLicStatus',
    SUBSTANCES = 'substances',
    START_DATE = 'startDate',
    END_DATE = 'endDate',
}

export enum DomainCodes {
    INTERNAL = "INT",
    EXTERNAL = "EXT"
}

export enum AllCasesTypeOfAuthority {
    CUSTOMS = 'CUSTOMS_AUTH',
    NON_CUSTOMS = 'NON_CUSTOMS_AUTH',
    NA = 'N/A'
}

export enum GridColumnType {
    INTEGER = "INTEGER",
    DECIMAL = "DECIMAL",
    SCALE_3 = "SCALE_3",
    SCALE_5 = "SCALE_5"
}

export enum LicitTradesTableColumns {
    SUBSTANCE_NAME = 'substanceName',
    SUBSTANCE_CATEGORY = 'substanceCategory',
    UN_TABLE = 'unTable',
    QUANTITY = 'quantity',
    MEASURE_UNIT = 'measureUnit',
    IS_IMPORT = 'isImport',
    COUNTRY = 'country'
}

export enum LicitUsesAndNeedsTableColumns {
    SUBSTANCE_NAME = 'substanceName',
    SUBSTANCE_CATEGORY = 'substanceCategory',
    UN_TABLE = 'unTable',
    QUANTITY = 'quantity',
    MEASURE_UNIT = 'measureUnit'
}

export enum EncodingEvent {
    COMPLETE_ENCODING = 'completeEncoding',
    INCOMPLETE_ENCODING = 'incompleteEncoding'
}

export enum SessionStorageKey {
    LAST_LICIT_TRADE_ADDED = 'lastLicitTradeAdded',
    LAST_LICIT_USE_ADDED = 'lastLicitUseAdded',
    SORTING_PARAMS_FORMD_LT = 'sortingParamsFormDLT',
    SORTING_PARAMS_FORMD_LU = 'sortingParamsFormDLU'
}

export enum SubstanceUnitTest {
    Kilograms = 1,
    Litres = 2,
    Grams = 3,
    Millilitres = 4
}

export enum SubstanceUnitCode {
    Kilograms = 'KG',
    Litres = 'LT',
    Grams = 'GM',
    Millilitres = 'ML'
}

export enum SubstanceCategoryCode {
    NONE = 'NON',
    CAT1 = 'CT1',
    CAT2 = 'CT2',
    CAT3 = 'CT3',
    CAT4 = 'CT4',
}

export enum SubstanceUnTableCode {
    NONE = 'NONE',
    T1 = 'T1',
    T2 = 'T2'
}

export enum SubstanceTypeCode {
    SCHEDULED = 'SC',
    NON_SCHEDULED = 'NSC',
    OTHER = 'OTH'
}

export enum SubstanceUnitGroup {
    gram = 'gramGroup',
    litre = 'litreGroup'
}

export enum SubstanceUnitGroupToken {
    gramGroup = 'gram',
    litreGroup = 'litre'
}
