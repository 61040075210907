import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { UxAppShellService } from '@eui/core';
import { ErrorBackendStatus } from '../enums/error-status';
import { TranslateService } from '@ngx-translate/core';
import { IResponseErrorDto } from '../dtos/i-response-error-dto';
import { START_YEAR } from '../constants/globals';
import { SubstanceUnitCode } from '@shared/enums/enums';

@Injectable({ providedIn: 'root' })
export class CommonUtilsService {
    constructor(private uxAppShellService: UxAppShellService, private translateService: TranslateService,) { }

    private blobFromFile(data: string | null, contentType: string, filename: string): File | null {
        if (!data) {
            return null;
        }
        let byteCharacters: string = '';
        if (data.indexOf('base64,') >= 0) {
            byteCharacters = window.atob(data.substring(data.indexOf('base64,') + 'base64,'.length));
        } else {
            byteCharacters = window.atob(data);
        }
        const byteArrays = [];
        const sliceSize = 512;
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        return new File(byteArrays, filename, {
            type: contentType
        });
    }

    public streamFile(data: string, contentType: string, filename: string): boolean {
        const file = this.blobFromFile(data, contentType, filename);
        if (!file) {
            return false;
        }
        saveAs(file, filename);
        return true;
    }

    public streamFileInTab(data: string, contentType: string, filename: string): boolean {
        const file = this.blobFromFile(data, contentType, filename);
        if (!file) {
            return false;
        }
        const url = window.URL.createObjectURL(file);
        window.open(url, '_blank', 'noopener, noreferrer');
        return true;
    }

    public handleAndPresentGenericErrorInGrowErrorOnce(uxAppShellService: UxAppShellService, translateService: TranslateService, error: any, summaryGrowl: string) {
        let errorMessage = '';
        if (error && error.status === ErrorBackendStatus.ERROR_UNPROCESSABLE_ENTITY_422
            && error.error && Object.keys(error.error).length > 0) {
            for (const key in error.error) {
                // Collect from all BR errors, their generic type messages and add them with new lines in a growl message.
                const brError = error.error[key];
                if (brError && brError.types && brError.types.GENERIC) {
                    errorMessage = (errorMessage ? '<br />' : '') + errorMessage + brError.types.GENERIC;
                }
            }
        }
        uxAppShellService.growl(
            {
                severity: 'danger',
                summary: translateService.instant(summaryGrowl ? summaryGrowl : 'dg.grow.generic.failure.generic.summary'),
                detail: errorMessage ? errorMessage : translateService.instant('dg.grow.generic.failure.generic.text')
            },
            false, // sticky.
            false, // multiple.
            4000, // life.
            'bottom-right', // position.
        );
    }

    public getResponseErrorAndDisplayGenericErrors<T extends IResponseErrorDto>(error: any, fromObj: (obj: any) => T, summary?: string): T | null {
        if (error && error.status === ErrorBackendStatus.ERROR_UNPROCESSABLE_ENTITY_422) {
            const responseErrorDto: T = fromObj(error.error);
            if (responseErrorDto?.hasGenericUnprocessableEntityError) {
                this.displayErrorGrowl({
                    summary,
                    detail: this.translateService.instant('dg.grow.generic.failure.generic.unprocessable.entity.text')
                });
            }
            return responseErrorDto;
        } else if (error && error.status === ErrorBackendStatus.ERROR_FORBIDDEN_403) {
            this.displayErrorGrowl({
                summary,
                detail: this.translateService.instant('dg.grow.generic.failure.generic.forbidden.error.text')
            });
            return null;
        } else {
            this.displayErrorGrowl({
                summary,
                detail: this.translateService.instant('dg.grow.generic.failure.generic.unexpected.error.text')
            });
            return null;
        }
    }

    displaySuccessGrowl(options: {
        summary?: string;
        detail?: string;
    }) {
        this.uxAppShellService.growl(
            {
                severity: 'success',
                summary: options?.summary ? options.summary : this.translateService.instant('dg.grow.generic.success.generic.summary'),
                detail: options?.detail
            },
            false, // sticky.
            false, // multiple.
            4000, // life.
            'bottom-right', // position.
        );
    }

    displayErrorGrowl(options: {
        summary?: string;
        detail?: string;
        sticky?: boolean;
        life?: number;
    }) {
        this.uxAppShellService.growl(
            {
                severity: 'danger',
                summary: options?.summary ? options.summary : this.translateService.instant('dg.grow.generic.failure.generic.summary'),
                detail: options?.detail
            },
            options?.sticky ? options.sticky : false, // sticky.
            false, // multiple.
            options?.life ? options.life : 4000, // life
            'bottom-right', // position.
        );
    }

    static yearOptionsFromDate(date: string): string[] {
        const year = date.split('/')[2];

        const result: string[] = [];
        let index = START_YEAR;
        while (index <= Number(year)) {
            result.push(index.toString());
            index++;
        }

        return result.reverse();
    }

    static quarterStatOptionsFromDate(date: string): string[] {
        const month = date.split('/')[1];
        const year = date.split('/')[2];

        const result: string[] = [];
        let index = 0;
        while (START_YEAR + index < Number(year)) {
            CommonUtilsService.addQuarters(result, (START_YEAR + index).toString(), 4);
            index++;
        }

        if (Number(month) <= 3) {
            CommonUtilsService.addQuarters(result, year, 1);
        } else if (Number(month) <= 6) {
            CommonUtilsService.addQuarters(result, year, 2);
        } else if (Number(month) <= 9) {
            CommonUtilsService.addQuarters(result, year, 3);
        } else {
            CommonUtilsService.addQuarters(result, year, 4);
        }

        return result.reverse();
    }

    static isSubstanceObsoleteOrDeleted(substance: any): boolean {
        if (!substance) return true;

        const now = new Date().getTime();

        if (substance.validUntil !== null && now > new Date(substance.validUntil).getTime()) { // Valid date overdue
            return true;
        }
        if (!substance.active) { // Substance is not active
            return true;
        }
        if (new Date(substance.validFrom).getTime() > now) { // Substance is not valid yet
            return true;
        }
        return false;
    }

    static getScaleBySubstanceUnit(substanceType: string | number): number {
        switch (substanceType) {
            case SubstanceUnitCode.Kilograms:
            case 'Kilogram':
            case 'Kilogram (KG)':
            case SubstanceUnitCode.Litres:
            case 'Litre':
            case 'Litre (LT)':
                return 5;

            case SubstanceUnitCode.Grams:
            case 'Gram':
            case 'Gram (GM)':
            case SubstanceUnitCode.Millilitres:
            case 'Millilitre':
            case 'Millilitre (ML)':
                return 3;

            default:
                return 3
        }
    }

    private static addQuarters(list: string[], year: string, numberOfQuarters: number) {
        let quarter = 0;
        while (numberOfQuarters - quarter > 0) {
            quarter++;
            list.push(year + '-' + quarter);
        }
    }
}
