import {IResponseErrorDto} from './i-response-error-dto';

export class DeclinePrivacyStatementResponseDto {

    static fromObj(obj: any): DeclinePrivacyStatementResponseDto | null {
        if (!obj) {
            return null;
        }
        return new DeclinePrivacyStatementResponseDto();
    }
}

export class DeclinePrivacyStatementResponseErrorDto implements IResponseErrorDto {
    hasGenericUnprocessableEntityError = false;

    static fromObj(obj: any): DeclinePrivacyStatementResponseErrorDto | null {
        if (!obj) {
            return null;
        }
        const declinePrivacyStatementResponseErrorDto = new DeclinePrivacyStatementResponseErrorDto();
        declinePrivacyStatementResponseErrorDto.hasGenericUnprocessableEntityError = obj.hasGenericUnprocessableEntityError;
        return declinePrivacyStatementResponseErrorDto;
    }
}
