import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, Observable, throwError} from 'rxjs';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

    /**
     * Intercept the HttpRequest
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Create duplicate (clone) of HttpRequest because they are immutable.
        // Old headers are kept.
        const duplicate = req.clone(); // Make any changes here in the request if needed.
        // Now handle the duplicate HttpRequest.
        return next.handle(duplicate)
        .pipe<any>(
            catchError(e => this.handleError(e))
        );
    }

    /**
     * Handle error of request
     */
    handleError(error: any): any {
        if(error) {
            // Handle error as needed.
        }
        return throwError(() => error);
      }

}
