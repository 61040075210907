export class AdvancedFilters {
    advancedFilterRows: AdvancedFilter[];
    chosenRule: string;

    constructor(advancedFilterRows: AdvancedFilter[], chosenRule: string){
        this.advancedFilterRows = advancedFilterRows;
        this.chosenRule = chosenRule;
    }

    public static fromObj(obj: any): AdvancedFilters {
        if (!obj) {
            return null;
        }

        return new AdvancedFilters(AdvancedFilter.listFromObjList(obj.advancedFilterRows),obj.chosenRule);
    }
}

export class AdvancedFilter {
    column: string;
    filterType: string;
    userInput: string;

    constructor(column: string, filterType: string, userInput: string) {
        this.column = column;
        this.filterType = filterType;
        this.userInput = userInput;
    }

    public static fromObj(obj: any): AdvancedFilter {
        if (!obj) {
            return null;
        }

        return new AdvancedFilter(obj.column, obj.filterType, obj.userInput);
    }

    public static listFromObjList(objs: any[]): AdvancedFilter[] {
        if (!objs) {
            return null;
        }

        return objs.map(obj => AdvancedFilter.fromObj(obj));
    }

}

export class AdvancedFiltersExtended {
    advancedFilterRows: AdvancedFilterExtended[];
    chosenRule: string;

    constructor(advancedFilterRows: AdvancedFilterExtended[], chosenRule: string){
        this.advancedFilterRows = advancedFilterRows;
        this.chosenRule = chosenRule;
    }

    public static fromObj(obj: any): AdvancedFiltersExtended {
        if (!obj) {
            return null;
        }

        return new AdvancedFiltersExtended(AdvancedFilterExtended.listFromObjList(obj.advancedFilterRows),obj.chosenRule);
    }
}

export class AdvancedFilterExtended extends AdvancedFilter {
    columnType: string;
    keywordType: string;

    constructor(column: string, filterType: string, userInput: string, columnType: string, keywordType: string) {
        super(column, filterType, userInput);
        this.columnType = columnType;
        this.keywordType = keywordType

    }

    public static fromObj(obj: any): AdvancedFilterExtended {
        if (!obj) {
            return null;
        }

        return new AdvancedFilterExtended(obj.column, obj.filterType, obj.userInput, obj.columnType, obj.keywordType);
    }

    public static listFromObjList(objs: any[]): AdvancedFilterExtended[] {
        if (!objs) {
            return null;
        }

        return objs.map(obj => AdvancedFilterExtended.fromObj(obj));
    }

}

export class AdvancedFilterOption {
    value: string;
    label: string;
    selected: boolean;

    constructor(value: string, label: string, selected: boolean) {
        this.value = value;
        this.selected = selected;
        this.label = label;
    }

    public static fromObj(obj: any): AdvancedFilterOption {
        if (!obj) {
            return null;
        }

        return new AdvancedFilterOption(obj.value, obj.label, obj.selected);
    }

    public static listFromObjList(objs: any[]): AdvancedFilterOption[] {
        if (!objs) {
            return null;
        }

        return objs.map(obj => AdvancedFilterOption.fromObj(obj));
    }
}

export class AdvancedFilterColumnOption extends AdvancedFilterOption{
    columnType: string;
    keywordType: string;

    constructor(value: string, label: string, selected: boolean, columnType: string, keywordType: string) {
        super(value, label, selected);
        this.columnType = columnType;
        this.keywordType = keywordType;
    }

    public static fromObj(obj: any): AdvancedFilterColumnOption {
        if (!obj) {
            return null;
        }

        return new AdvancedFilterColumnOption(obj.value, obj.label, obj.selected, obj.columnType, obj.keywordType);
    }

    public static listFromObjList(objs: any[]): AdvancedFilterColumnOption[] {
        if (!objs) {
            return null;
        }

        return objs.map(obj => AdvancedFilterColumnOption.fromObj(obj));
    }
}

export class AdvancedFilterTypesOption {
    numeric: AdvancedFilterOption[];
    nonNumeric: AdvancedFilterOption[];
    categorical: AdvancedFilterOption[];

    constructor(numeric: AdvancedFilterOption[], nonNumeric: AdvancedFilterOption[], categorical: AdvancedFilterOption[]) {
        this.numeric = numeric;
        this.nonNumeric = nonNumeric;
        this.categorical = categorical;

    }

    public static fromObj(obj: any): AdvancedFilterTypesOption {
        if (!obj) {
            return null;
        }
        return new AdvancedFilterTypesOption(AdvancedFilterOption.listFromObjList(obj.numeric), AdvancedFilterOption.listFromObjList(obj.nonNumeric), AdvancedFilterOption.listFromObjList(obj.categorical));
    }
}

export class AdvancedFilterSelectValuesOption {
    yesNo: AdvancedFilterOption[];
    status: AdvancedFilterOption[];
    completed: AdvancedFilterOption[];
    typeOfAuthority: AdvancedFilterOption[];

    constructor(yesNo: AdvancedFilterOption[], status: AdvancedFilterOption[], completed: AdvancedFilterOption[], typeOfAuthority: AdvancedFilterOption[]) {
        this.yesNo = yesNo;
        this.status = status;
        this.completed = completed;
        this.typeOfAuthority = typeOfAuthority;
    }

    public static fromObj(obj: any): AdvancedFilterSelectValuesOption {
        if (!obj) {
            return null;
        }
        return new AdvancedFilterSelectValuesOption(
            AdvancedFilterOption.listFromObjList(obj.yesNo),
            AdvancedFilterOption.listFromObjList(obj.status),
            AdvancedFilterOption.listFromObjList(obj.completed),
            AdvancedFilterOption.listFromObjList(obj.typeOfAuthority),
        );
    }
}
