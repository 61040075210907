<div class="container">
    <div class="row">
        <div class="col-md-3 left">
            <button euiButton euiSizeM euiTooltip="{{ 'dg.grow.advanced.search.dialog.footer.reset' | translate }}"
                    (click)="onResetFilterClicked()" class="eui-u-mr-s">
                <eui-icon-svg icon="eui-undo"></eui-icon-svg>
                <span euiLabel>{{ 'dg.grow.advanced.filter.reset.button' | translate }}</span>
            </button>
        </div>

        <div class="col-md-9 right">
            <button euiButton euiSizeM euiTooltip="{{ 'dg.grow.advanced.search.dialog.footer.cancel' | translate }}"
                    (click)="onCancelClicked()" class="eui-u-mr-s">
                <span euiLabel>{{ 'dg.grow.advanced.filter.footer.cancel.button' | translate }}</span>
            </button>

            <button euiButton euiPrimary euiSizeM euiTooltip="{{ 'dg.grow.advanced.search.dialog.footer.search' | translate }}"
                    (click)="onFindClicked()">
                <eui-icon-svg icon="eui-search"></eui-icon-svg>
                <span euiLabel>{{ 'dg.grow.advanced.filter.footer.search.button' | translate }}</span>
            </button>
        </div>
    </div>
</div>
