import {Inject, Injectable} from '@angular/core';
import {CONFIG_TOKEN, EuiAppConfig, EuiServiceStatus, UserDetails, UserService, UserState} from '@eui/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, map, Observable, of, switchMap, zip} from 'rxjs';

import {PermissionCodes} from '../auth/auth-path-permission-mapping';
import {CustomUserDetails, CustomUserDetailsDto} from '../custom-user-details';
import {UpdateLastLogonResponseDto} from '../dtos/update-last-logon.dto';
import {ADMINISTRATION_API_URL, POST_LOGOUT_REDIRECT_URI} from '../../../environments/environment';
import {AuthConstants} from '../auth/auth-constants';
import {DeclinePrivacyStatementResponseDto} from '../dtos/decline-privacy-statement.dto';
import {AcceptPrivacyStatementResponseDto} from '../dtos/accept-privacy-statement.dto';

declare var OpenIdConnect: any; // Already defined in openid-login.js. In order to use it here just declare it in the .ts.
// OpenIdConenct here is needed for getting the idToken, from which we can use it to logout.

@Injectable({
    providedIn: 'root',
})
export class AuthUtils {
    private readonly _isAuthFinished$: BehaviorSubject<boolean>;

    constructor(
        @Inject(CONFIG_TOKEN) private config: EuiAppConfig,
        private http: HttpClient,
        private userService: UserService
    ) {

        this._isAuthFinished$ = new BehaviorSubject<boolean>(false);
    }

    get isAuthFinished$() {
        return this._isAuthFinished$;
    }

    setIsAuthFinishedValue(value: boolean) {
        return this._isAuthFinished$.next(value);
    }

    public static convertUserStateToCustomUserDetails(userState: UserState): CustomUserDetails {
        if (userState) {
            return {
                username: userState.username,
                name: userState.name,
                email: userState.email,
                userRoles: userState.userRoles,
                userRolesNames: userState.userRolesNames,
                userPermissions: userState.userPermissions,
                caName: userState.caName,
                caCountry: userState.caCountry,
                caOperatorEncoding: userState.caOperatorEncoding,
                privacyStatementAccepted: userState.privacyStatementAccepted,
            };
        }
        return null;
    }

    public static getPermissionCodesKeyByValue(value: string): string | null {
        const indexOfPermissionCodeValue = Object.values(PermissionCodes).indexOf(value as unknown as PermissionCodes);
        if (indexOfPermissionCodeValue >= 0) {
            return Object.keys(PermissionCodes)[indexOfPermissionCodeValue];
        }
        return null;
    }

    public static getPermissionCodesEnumByValue(value: string): PermissionCodes | null {
        const key = AuthUtils.getPermissionCodesKeyByValue(value);
        if (key) {
            return PermissionCodes[key as keyof typeof PermissionCodes];
        }
        return null;
    }

    updateLastLogon(): Observable<UpdateLastLogonResponseDto> {
        const fetchAuthorityOptionsDataUrl = `${ADMINISTRATION_API_URL}/logged-in/update-last-logon`;
        return this.http.put(fetchAuthorityOptionsDataUrl, {}).pipe(map(UpdateLastLogonResponseDto.fromObj));
    }

    /**
     * Fetches user details,
     * create user: UserState object
     * then initialise to the UserService on run time
     */
    initUserService(): Observable<any> {
        return zip(
            this.fetchUserDetails(),
        ).pipe(
            switchMap(([userDetails]) => this.userService.init(userDetails)));
    }

    /**
     * Fetches user details from back-end.
     */
    private fetchUserDetails(): Observable<UserDetails> {
        const moduleCoreApi = this.config.modules.core;
        const url = `${moduleCoreApi.base}${moduleCoreApi.userDetails}`;
        const user = {userId: AuthConstants.annonymous};
        if (!url) {
            return of(user);
        }
        return this.http.get<CustomUserDetailsDto>(url).pipe(
            map((customUserDetailsDto) => {
                // Convert to Set.
                const userRoles: Set<string> = new Set<string>();
                let userRolesNames: string[] = [];
                if (customUserDetailsDto.userRoles) {
                    const values: string[] = Object.values(customUserDetailsDto.userRoles).map((labelValue => String(labelValue.value)));
                    values.forEach(value => {
                        userRoles.add(value);
                    });
                    userRolesNames = Object.values(customUserDetailsDto.userRoles).map((labelValue => String(labelValue.label)));
                }

                const userPermissions: Set<PermissionCodes> = new Set<PermissionCodes>();
                if (customUserDetailsDto.userPermissions) {
                    const keys: string[] = Object.keys(customUserDetailsDto.userPermissions);
                    keys.forEach(key => {
                        userPermissions.add(AuthUtils.getPermissionCodesEnumByValue((customUserDetailsDto.userPermissions)[key]));
                    });

                }
                const userDetails: UserDetails = {
                    ...customUserDetailsDto,
                    userId: customUserDetailsDto.username,
                    userRoles,
                    userRolesNames,
                    userPermissions
                };
                return userDetails;
            })
        );
    }

    /**
     * Initializes userService with annonymous.
     */
    public initUserServiceAnonymous(): Observable<EuiServiceStatus> {
        const user = {
            userId: AuthConstants.annonymous
        };

        return this.userService.init(user);
    }

    /**
     * Update userService state with annonymous.
     */
    public updateUserServiceAnonymous(): void {
        const user = {
            userId: AuthConstants.annonymous
        };

        this.userService.updateState(user);
    }

    public isAuthenticated(): Promise<boolean> {
        return new Promise((resolve) => {
            OpenIdConnect.getIdToken((idToken) => {
                resolve(idToken != null)
            });
        });
    }

    public loginWithOpenIDConnect() {
        return OpenIdConnect.loginWithOpenIDConnect()
    }

    async logout() {
        if (OpenIdConnect && typeof OpenIdConnect !== 'undefined' && OpenIdConnect.config && OpenIdConnect.SESSION_STORAGE_KEY_ID_TOKEN) {
            const idToken = sessionStorage.getItem(OpenIdConnect.SESSION_STORAGE_KEY_ID_TOKEN);
            let logoutUrl = OpenIdConnect.config.logoutUrl;
            // Clear Session storage
            sessionStorage.clear();
            // Redirect to ECAS logout URL
            window.location.href = logoutUrl + '?id_token_hint=' + idToken + '&post_logout_redirect_uri=' + encodeURIComponent(POST_LOGOUT_REDIRECT_URI);
        } else {
            console.error('Wrong OpenID configuration');
        }
    }
    declinePrivacyStatement(): Observable<UpdateLastLogonResponseDto> {
        const declinePrivacyStatementUrl = `${ADMINISTRATION_API_URL}/logged-in/decline-privacy-statement`;
        return this.http.post(declinePrivacyStatementUrl, {}).pipe(map(DeclinePrivacyStatementResponseDto.fromObj));
    }

    acceptPrivacyStatement(): Observable<UpdateLastLogonResponseDto> {
        const acceptPrivacyStatementUrl = `${ADMINISTRATION_API_URL}/logged-in/accept-privacy-statement`;
        return this.http.post(acceptPrivacyStatementUrl, {}).pipe(map(AcceptPrivacyStatementResponseDto.fromObj));
    }
}
