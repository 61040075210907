import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-redirect',
  template: ''
})
export class RedirectComponent implements OnInit {

  ngOnInit(): void {
    console.debug('');
  }

}
