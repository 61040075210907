import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'formatNumberToLocaleString',
})
export class FormatNumberToLocaleStringPipe implements PipeTransform {
    transform(value: any, scale: number = 0): string {
        if (value == null) {
            return '';
        } else if (typeof value === 'number') {
            return value.toLocaleString('en-US', {minimumFractionDigits: scale, maximumFractionDigits: scale});
        } else if (typeof value === 'string') {
            const newValue = value.replace(',', '.');
            const myn = Number(newValue);
            if (isNaN(myn)) {
                return value;
            } else {
                return myn.toLocaleString('en-US', {minimumFractionDigits: scale, maximumFractionDigits: scale});
            }
        } else {
            return value;
        }
    }
}
