import {Inject, Injectable} from '@angular/core';
import {CONFIG_TOKEN, EuiAppConfig, I18nService, UserPreferences} from '@eui/core';
import {defer, from, Observable, switchMap, take, zip} from 'rxjs';
import {AuthUtils} from './shared/services/auth-utils.service';

@Injectable({
    providedIn: 'root',
})
export class AppStarterService {
    defaultUserPreferences: UserPreferences;

    constructor(
        protected i18nService: I18nService,
        @Inject(CONFIG_TOKEN) private config: EuiAppConfig,
        private authUtils: AuthUtils
    ) {
    }

    /**
     * this method is called by AppModule at the beginning
     * it initializes i18nService and userService
     * the AppComponent starts after all these are finished
     */
    public start(): Observable<any> {
        return zip(
            this.initializeUserState().pipe(
                // after user state is initialized, then initialize i18nService
                switchMap((userStatus) => this.i18nService.init()),
            ),
        );
    }

    /**
     * initializes userService either by logged in user details or as anonymous user.
     */
    private initializeUserState(): Observable<any> {
        return defer(() => from(
            Promise.resolve().then(async () => {
                const isAuthenticated = await this.authUtils.isAuthenticated();
                if (isAuthenticated) {
                    // initialize user state with enhanced details from backend
                    this.authUtils.initUserService().pipe(take(1)).subscribe(() => {
                        this.authUtils.setIsAuthFinishedValue(true);
                    });
                } else {
                    // initialize user state with anonymous user
                    this.authUtils.initUserServiceAnonymous().pipe(take(1)).subscribe(() => {
                        this.authUtils.setIsAuthFinishedValue(true);
                    });
                }
            }).catch(error => {
                console.error(error);
            })
        ));
    }
}
