import {ModulesConfig} from '@eui/core';
import {ADMINISTRATION_API_URL} from '../environments/environment';

const USER_API_PATH_FROM_ENV = ADMINISTRATION_API_URL + 'api/';

export const MODULES: ModulesConfig = {
    core: {
        base: USER_API_PATH_FROM_ENV,
        user: {
            base: 'user-info',
            details: '/user-details',
            preferences: '/user-preferences'
        },
    }
};
