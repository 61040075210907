import {IResponseErrorDto} from './i-response-error-dto';

export class AcceptPrivacyStatementResponseDto {

    static fromObj(obj: any): AcceptPrivacyStatementResponseDto | null {
        if (!obj) {
            return null;
        }
        return new AcceptPrivacyStatementResponseDto();
    }
}

export class AcceptPrivacyStatementResponseErrorDto implements IResponseErrorDto {
    hasGenericUnprocessableEntityError = false;

    static fromObj(obj: any): AcceptPrivacyStatementResponseErrorDto | null {
        if (!obj) {
            return null;
        }
        const acceptPrivacyStatementResponseErrorDto = new AcceptPrivacyStatementResponseErrorDto();
        acceptPrivacyStatementResponseErrorDto.hasGenericUnprocessableEntityError = obj.hasGenericUnprocessableEntityError;
        return acceptPrivacyStatementResponseErrorDto;
    }
}
