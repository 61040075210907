<div class="print-privacy-statement-visible">
    <p class="print-privacy-statement-hidden">
        {{'dg.grow.privacy.statement.body.preintro.par.text' | translate}}
    </p>
    <br class="print-privacy-statement-hidden">

    <h4><strong>{{'dg.grow.privacy.statement.body.title' | translate}}</strong></h4>
    <p>
        {{'dg.grow.privacy.statement.body.intro.par.text1' | translate}}<br>
        {{'dg.grow.privacy.statement.body.intro.par.text2' | translate}}
    </p>
    <br>

    <h5><strong>{{'dg.grow.privacy.statement.body.table.of.contents' | translate}}</strong></h5>
    <a (click)="scrollToElementById('section1')">
        <h5>{{'dg.grow.privacy.statement.body.section1.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section2')">
        <h5>{{'dg.grow.privacy.statement.body.section2.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section3')">
        <h5>{{'dg.grow.privacy.statement.body.section3.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section4')">
        <h5>{{'dg.grow.privacy.statement.body.section4.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section5')">
        <h5>{{'dg.grow.privacy.statement.body.section5.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section6')">
        <h5>{{'dg.grow.privacy.statement.body.section6.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section7')">
        <h5>{{'dg.grow.privacy.statement.body.section7.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section8')">
        <h5>{{'dg.grow.privacy.statement.body.section8.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section9')">
        <h5>{{'dg.grow.privacy.statement.body.section9.title' | translate}}</h5>
    </a>
    <a (click)="scrollToElementById('section10')">
        <h5>{{'dg.grow.privacy.statement.body.section10.title' | translate}}</h5>
    </a>
    <br>

    <!-- SECTION 1 -->
    <h5 id="section1" name="section1"><strong>{{'dg.grow.privacy.statement.body.section1.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section1.par1.text1' | translate}}
        <a target="_blank" rel="noopener"
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2018.295.01.0039.01.ENG&toc=OJ:L:2018:295:TOC">{{'dg.grow.privacy.statement.body.section1.par1.link1'
            | translate}}</a>
        {{'dg.grow.privacy.statement.body.section1.par1.text2' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section1.par2.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section1.par3.text' | translate}}
    </p>
    <br>

    <!-- SECTION 2 -->
    <h5 id="section2" name="section2"><strong>{{'dg.grow.privacy.statement.body.section2.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par1.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par2.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par3.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par4.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par5.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par6.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par7.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par8.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par9.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section2.par10.text' | translate}}
    </p>
    <br>

    <!-- SECTION 3 -->
    <h5 id="section3" name="section3"><strong>{{'dg.grow.privacy.statement.body.section3.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section3.par1.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section3.par2.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section3.par3.text' | translate}}
    </p>
    <br>

    <!-- SECTION 4 -->
    <h5 id="section4" name="section4"><strong>{{'dg.grow.privacy.statement.body.section4.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section4.par1.text' | translate}}
    </p>
    <br>

    <!-- SECTION 5 -->
    <h5 id="section5" name="section5"><strong>{{'dg.grow.privacy.statement.body.section5.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section5.par1.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section5.par2.text' | translate}}
    </p>
    <br>

    <!-- SECTION 6 -->
    <h5 id="section6" name="section4"><strong>{{'dg.grow.privacy.statement.body.section6.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section6.par1.text1' | translate}}
        <a target="_blank" rel="noopener"
            href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1548093747090&uri=CELEX:32017D0046">{{'dg.grow.privacy.statement.body.section1.par1.link1'
            | translate}}</a>
        {{'dg.grow.privacy.statement.body.section6.par1.text2' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section6.par2.text' | translate}}
    </p>
    <br>

    <!-- SECTION 7 -->
    <h5 id="section7" name="section7"><strong>{{'dg.grow.privacy.statement.body.section7.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section7.par1.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section7.par2.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section7.par3.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section7.par4.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section7.par5.text' | translate}}
    </p>
    <br>

    <!-- SECTION 8 -->
    <h5 id="section8" name="section8"><strong>{{'dg.grow.privacy.statement.body.section8.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section8.par1.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section8.par2.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section8.par3.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section8.par4.text' | translate}}
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section8.par5.text' | translate}}
    </p>
    <br>

    <!-- SECTION 9 -->
    <h5 id="section9" name="section9"><strong>{{'dg.grow.privacy.statement.body.section9.title' | translate}}</strong></h5>
    <br>
    <h6><strong>{{'dg.grow.privacy.statement.body.section9.par1.title' | translate}}</strong></h6>
    <p>
        {{'dg.grow.privacy.statement.body.section9.par1.text' | translate}}
        <a rel="noopener" href="mailto:GROWF2@ec.europa.eu">GROWF2@ec.europa.eu</a>.
    </p>
    <br>
    <h6><strong>{{'dg.grow.privacy.statement.body.section9.par2.title' | translate}}</strong></h6>
    <p>
        <!-- Must be on same line other wise it leaves a space before and after (http://bugzilla.eurodyn.com/show_bug.cgi?id=141185#c20) -->
        {{'dg.grow.privacy.statement.body.section9.par2.text1' | translate }}<a rel="noopener" href="mailto:DATA-PROTECTION-OFFICER@ec.europa.eu">DATA-PROTECTION-OFFICER@ec.europa.eu</a>{{'dg.grow.privacy.statement.body.section9.par2.text2' | translate}}
    </p>
    <br>
    <h6><strong>{{'dg.grow.privacy.statement.body.section9.par3.title' | translate}}</strong></h6>
    <p>
        <!-- Must be on same line other wise it leaves a space before and after (http://bugzilla.eurodyn.com/show_bug.cgi?id=141185#c20) -->
        {{'dg.grow.privacy.statement.body.section9.par3.text1' | translate}}<a rel="noopener" href="mailto:edps@edps.europa.eu">edps@edps.europa.eu</a>
        {{'dg.grow.privacy.statement.body.section9.par3.text2' | translate}}
        <a target="_blank" rel="noopener" href="https://edps.europa.eu/data-protection/ourrole-supervisor/complaints_en">https://edps.europa.eu/data-protection/ourrole-supervisor/complaints_en</a>{{'dg.grow.privacy.statement.body.section9.par3.text3' | translate}}
    </p>
    <br>

    <!-- SECTION 10 -->
    <h5 id="section10" name="section10"><strong>{{'dg.grow.privacy.statement.body.section10.title' | translate}}</strong></h5>
    <p>
        {{'dg.grow.privacy.statement.body.section10.par1.text' | translate}}
        <a target="_blank" rel="noopener" href="http://ec.europa.eu/dpo-register">http://ec.europa.eu/dpo-register</a>.
    </p>
    <p>
        {{'dg.grow.privacy.statement.body.section10.par2.text' | translate}}
    </p>
</div>
