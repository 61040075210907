import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {PATH_PERMISSION_MAPPING, PermissionCodes} from '../auth/auth-path-permission-mapping';
import {UserService, UserState} from '@eui/core';
import {AuthUtils} from '../../shared/services/auth-utils.service';
import {Pages} from '../enums/enums';
import {AuthConstants} from '../auth/auth-constants';
import {CustomUserDetails} from "../custom-user-details";

/**
 * A guard for protected pages that need specific permissions
 * Allows an Authenticated and Valid user only if the user
 * has at least one permission in PATH_PERMISSION_MAPPING of this page
 */
@Injectable({
    providedIn: 'root',
})
export class AuthorizationGuardService implements CanActivate {
    constructor(
        private readonly _router: Router,
        private userService: UserService,
        private authUtils: AuthUtils,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {
            this.authUtils.isAuthFinished$.subscribe(isAuthFinished => {
                // wait until authentication flow is finished by app-starter service
                if (!isAuthFinished) {
                    return;
                }
                this.userService.getState().subscribe({
                    next: (user: UserState) => {
                        // convert to CustomUserDetails
                        const userInfos = AuthUtils.convertUserStateToCustomUserDetails({ ...user });
                        const isAuthenticated: boolean = userInfos?.username !== null && userInfos?.username !== undefined;
                        const isValid = isAuthenticated && userInfos?.username !== AuthConstants.invalid;

                        if (!isAuthenticated) {
                            // if user is not authenticated then navigate to home
                            this._router.navigate([Pages.HOME]);
                            resolve(false);
                            return;
                        }
                        if (!isValid) {
                            // if user is not valid then navigate to unauthorized
                            this._router.navigate(['/unauthorized']);
                            resolve(false);
                            return;
                        }

                        // user is Authenticated
                        // Now check permission.
                        let accessGranted = this.calculateAccessGranted(state, userInfos);

                        if (!accessGranted) {
                            this._router.navigate(['/unauthorized']);
                        }
                        resolve(accessGranted);
                    }, error: (error) => {
                        // in case of error navigate to home
                        console.error(error);
                        this._router.navigate([Pages.HOME]);
                        reject(error);
                    }
                });
            });
        });
    }

    /**
     * Calculates the accessGranted boolean base on
     * the permissions of the user and the accepted permissions of page from PATH_PERMISSION_MAPPING
     */
    private calculateAccessGranted(state: RouterStateSnapshot, userInfos: CustomUserDetails): boolean{
        const permissionsOfUser: Set<PermissionCodes> = userInfos.userPermissions;
        const pathPermissions: Set<PermissionCodes> = PATH_PERMISSION_MAPPING[state.url];
        let accessGranted = false;
        if (pathPermissions && pathPermissions.size > 0 && permissionsOfUser && permissionsOfUser.size > 0) {
            // If at least one of the user permissions match the permissions of the path, grant the access.
            const intersectionOfPermissions = new Set([...permissionsOfUser].filter(i => pathPermissions.has(i)));
            accessGranted = intersectionOfPermissions && intersectionOfPermissions.size > 0;
        }
        return accessGranted;
    }

}
