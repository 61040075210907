import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdvancedFilterService} from '../../advanced-filter.service';

@Component({
  selector: 'footer-advanced-filter',
  templateUrl: './footer-advanced-filter.component.html',
  styleUrls: ['./footer-advanced-filter.component.scss']
})
export class FooterAdvancedFilterComponent implements OnInit {

  @Output() findClicked = new EventEmitter<boolean>();
  @Output() cancelClicked = new EventEmitter<boolean>();

  constructor(
    private advancedFilterService: AdvancedFilterService
  ) { }

  ngOnInit(): void {
    console.debug('');
  }

  onCancelClicked(){
    this.cancelClicked.emit(true);
  }

  onFindClicked(){
    this.findClicked.emit(true);
  }

  onResetFilterClicked(){
    this.advancedFilterService.setAdvancedFilterSubject();
}

}
