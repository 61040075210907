import {IResponseErrorDto} from "../../../dtos/i-response-error-dto";

export class CheckCompleteEncodeCasesRequestDto {
    yearSelected: number;

    static fromObj(obj: any): CheckCompleteEncodeCasesRequestDto {
        if(!obj) {
            return null;
        }
        const checkCompleteEncodeCasesRequestDto: CheckCompleteEncodeCasesRequestDto = new CheckCompleteEncodeCasesRequestDto();
        checkCompleteEncodeCasesRequestDto.yearSelected = obj.yearSelected;
        return checkCompleteEncodeCasesRequestDto;
    }
}

export class CheckCompleteEncodeCasesResponseDto {
    canComplete: boolean;

    static fromObj(obj: any): CheckCompleteEncodeCasesResponseDto | null {
        if (!obj) {
            return null;
        }
        const responseDto: CheckCompleteEncodeCasesResponseDto = new CheckCompleteEncodeCasesResponseDto();
        responseDto.canComplete = obj.canComplete;
        return responseDto;
    }
}

export class CheckCompleteEncodeCasesResponseErrorDto implements IResponseErrorDto {
    hasGenericUnprocessableEntityError: boolean = false;

    static fromObj(obj:any): CheckCompleteEncodeCasesResponseErrorDto {
        if(!obj) {
            return null;
        }
        const checkCompleteEncodeCasesResponseErrorDto: CheckCompleteEncodeCasesResponseErrorDto = new CheckCompleteEncodeCasesResponseErrorDto();
        checkCompleteEncodeCasesResponseErrorDto.hasGenericUnprocessableEntityError = obj.hasGenericUnprocessableEntityError;
        return checkCompleteEncodeCasesResponseErrorDto;
    }
}
