import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateUtils {

    static getCurrentDateFromTimezone(timezone: string): string {
        const locale = 'en-GB';
        return new Date().toLocaleDateString(locale, {timeZone: timezone});
    }

    static getDateFromISOStringAndTimezone(timezone: string, date: string): string {
        const locale = 'en-GB';
        return new Date(date).toLocaleDateString(locale, {timeZone: timezone});
    }

}
