import {AdvancedFilters} from '../../advanced-filter/models/advanced-filter.model';

export class PageToolbarOptions {
    yearQuarter?: string;
    year?: string;
    records?: string;
    competentAuthority?: string;
    advancedFilters?: AdvancedFilters;

    constructor(yearQuarter: string, year: string, records: string,
                competentAuthority: string, advancedFilters: AdvancedFilters) {
        this.yearQuarter = yearQuarter;
        this.year = year;
        this.records = records;
        this.competentAuthority = competentAuthority;
        this.advancedFilters = AdvancedFilters.fromObj(advancedFilters);
    }

    public static fromObj(obj: any): PageToolbarOptions {
        if (!obj) {
            return null;
        }

        return new PageToolbarOptions(obj.yearQuarter, obj.year, obj.records, obj.competentAuthority, obj.advancedFilters);
    }

    public static listFromObjList(objs: any[]): PageToolbarOptions[] {
        if (!objs) {
            return null;
        }
        return objs.map(obj => PageToolbarOptions.fromObj(obj));
    }
}

export class PageToolbarOptionsWithReset extends PageToolbarOptions {
    resetClicked?: boolean;

    constructor(yearQuarter: string, year: string, records: string,
                competentAuthority: string, advancedFilters: AdvancedFilters,
                resetClicked: boolean) {
        super(yearQuarter, year, records, competentAuthority, advancedFilters);
        this.resetClicked = resetClicked;
    }

    public static fromObj(obj: any): PageToolbarOptionsWithReset {
        if (!obj) {
            return null;
        }

        return new PageToolbarOptionsWithReset(obj.yearQuarter, obj.year, obj.records,
            obj.competentAuthority, obj.advancedFilters, obj.resetClicked);
    }

    public static listFromObjList(objs: any[]): PageToolbarOptionsWithReset[] {
        if (!objs) {
            return null;
        }
        return objs.map(obj => PageToolbarOptionsWithReset.fromObj(obj));
    }
}
