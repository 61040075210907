import {ElementRef, Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {EuiTabsComponent} from '@eui/components/eui-tabs';
import {EuiFieldsetComponent} from '@eui/components/eui-fieldset';

@Injectable({
    providedIn: 'root'
})
export class FormUtilsService {

    addOrSetControl(formGroup: FormGroup, formControlName: string, formControl: AbstractControl) {
        if (formGroup.get(formControlName)) {
            formGroup.get(formControlName).patchValue(formControl.value);
        } else {
            formGroup.addControl(formControlName, formControl);
        }
    }

    /**
     * ** IMPORTANT **
     *
     * Each tab **MUST** have its own formGroup.
     *
     * @param el
     * @param form top level FormGroups **MUST** represent a single tab
     * @param tabs each tab **MUST** have each own FormGroup
     */
    scrollToFirstInvalidControlAndSwitchTab(el: ElementRef, form: FormGroup, tabs: EuiTabsComponent): void {
        let index = 0;
        for(const formGroupName in form.controls) {
            if (formGroupName === 'id') {
                continue;
            }
            if(form.get(formGroupName).invalid) {
                tabs.changeTab(index);
                setTimeout(() => {
                    const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
                        'form .ng-invalid .ng-invalid'
                    );
                    if(firstInvalidControl) {
                        firstInvalidControl.focus();
                    }
                }, 0);
                return;
            }
            index++;
        }
    }

    scrollToFirstInvalidControlAndExpandFieldSet(el: ElementRef, form: FormGroup, fieldSets: EuiFieldsetComponent[]): void {
        const index = 0;
        for(const formGroupName in form.controls) {
            if(form.get(formGroupName).invalid) {
                fieldSets[index].isExpanded = true;
                setTimeout(() => {
                    const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
                        '[formControlName].ng-invalid'
                    );
                    if(firstInvalidControl) {
                        firstInvalidControl.focus();
                    }
                }, 0);
                return;
            }
        }
    }

    scrollToFirstInvalidControl(el: ElementRef): void {
        const firstInvalidControl: HTMLElement = el.nativeElement.querySelector(
            'form .ng-invalid'
        );
        if (firstInvalidControl) {
            firstInvalidControl.focus();
        }
    }
}
