import {Injectable} from '@angular/core';
import {map, Observable, of, Subject} from 'rxjs';
import {PageToolbarOptionsWithReset} from './models/page-toolbar-options.model';
import {HttpClient} from '@angular/common/http';
import {FetchPageToolbarDataAuthoritiesResponseDto, FetchPageToolbarDataFormDResponseDto, FetchPageToolbarDataRegistrationsLicensesResponseDto, FetchPageToolbarDataReportsResponseDto, FetchPageToolbarDataResponseDto, FilterOption} from './dtos/page-toolbar-data-options.dto';
import {Pages, RecordsValues} from '../../enums/enums';
import {CASES_API_URL, FORMD_API_URL} from 'src/environments/environment';
import {AdvancedFilterService} from '../advanced-filter/advanced-filter.service';
import {DateUtils} from '../../services/date-utils.service';
import {CommonUtilsService} from '../../services/common-utils.service';
import {CheckCompleteEncodeCasesRequestDto, CheckCompleteEncodeCasesResponseDto} from "./dtos/check-complete-encode-cases.dto";

@Injectable({
    providedIn: 'root'
})
export class PageToolbarService {
    private cachedFetchPageToolbarDataFormDResponseDto: FetchPageToolbarDataFormDResponseDto = null;
    private cachedFetchPageToolbarDataAuthoritiesResponseDto: FetchPageToolbarDataAuthoritiesResponseDto = null;
    private cachedFetchPageToolbarDataRegistrationsLicensesResponseDto: FetchPageToolbarDataRegistrationsLicensesResponseDto = null;
    constructor(
        protected http: HttpClient,
        private advancedFilterService: AdvancedFilterService
    ) {
        this.clearCacheForAllPages();
    }

    public getToolbars(): Observable<PageToolbarOptionsWithReset> {
        return this.toolbars.asObservable();
    }

    toolbars: Subject<PageToolbarOptionsWithReset> = new Subject<PageToolbarOptionsWithReset>();

    setToolbars(toolbars: PageToolbarOptionsWithReset) {
        this.toolbars.next(toolbars);
    }

    fetchYearOptions(): FilterOption[] {
        const results: FilterOption[] = [];
        const yearOptions = CommonUtilsService.yearOptionsFromDate(DateUtils.getCurrentDateFromTimezone('Europe/Brussels'));

        if (yearOptions) {
            yearOptions.forEach((option, index) => {
                if (index === 0) {
                    results.push(new FilterOption(option, option, true));

                } else {
                    results.push(new FilterOption(option, option, false));
                }
            });
        }

        return results;
    }

    fetchYearQuarterOptions(): FilterOption[] {
        const results: FilterOption[] = [];
        const yearQuarterOptions = CommonUtilsService.quarterStatOptionsFromDate(DateUtils.getCurrentDateFromTimezone('Europe/Brussels'));

        if (yearQuarterOptions) {
            const labelForSelected = yearQuarterOptions[0].split('-').join(' - ');
            results.push(new FilterOption(yearQuarterOptions[0], labelForSelected, true));

            const listWithoutSelected = yearQuarterOptions.slice(1);
            listWithoutSelected.forEach((option) => {
                results.push(new FilterOption(option, option.split('-').join(' - '), false));
            });
        }

        return results;
    }

    fetchFilterDataForPage(page: Pages, clearCache: boolean): Observable<FetchPageToolbarDataResponseDto> {
        if(clearCache) {
            this.clearCacheForPage(page);
        }
        switch (page) {
            case Pages.FORM_D:
                if(this.cachedFetchPageToolbarDataFormDResponseDto !== null) {
                    return of(this.cachedFetchPageToolbarDataFormDResponseDto);
                }
                const fetchFilterDataForFormDUrl = `${FORMD_API_URL}/fetch-filters`;
                return this.http.get(fetchFilterDataForFormDUrl).pipe(map(
                    result => {
                        this.cachedFetchPageToolbarDataFormDResponseDto = FetchPageToolbarDataFormDResponseDto.fromObj(result);
                        this.cachedFetchPageToolbarDataFormDResponseDto.years = this.fetchYearOptions();
                        return this.cachedFetchPageToolbarDataFormDResponseDto;
                    }
                ));
            case Pages.REGISTRATIONS_LICENCES:
                return of(new FetchPageToolbarDataRegistrationsLicensesResponseDto([
                    new FilterOption(RecordsValues.VALID, RecordsValues.VALID, true),
                    new FilterOption(RecordsValues.INVALID, RecordsValues.INVALID, false)
                ]));
            case Pages.REPORTS:
                return of( FetchPageToolbarDataReportsResponseDto.fromObj({years: this.fetchYearOptions()}));

            default:
                const res: any = {};
                return of(res);
        }

    }

    checkCanEncodeIncompleteCase(checkCompleteEncodeCasesRequest: CheckCompleteEncodeCasesRequestDto): Observable<CheckCompleteEncodeCasesResponseDto> {
        const checkCompleteEncodeCasesUrl = `${CASES_API_URL}/encode-cases/check-complete-encode-cases`
        return this.http.post(checkCompleteEncodeCasesUrl, checkCompleteEncodeCasesRequest).pipe(map(CheckCompleteEncodeCasesResponseDto.fromObj));
    }

    private clearCacheForPage(page: Pages) {
        switch (page) {
            case Pages.FORM_D:
                this.cachedFetchPageToolbarDataFormDResponseDto = null;
                break;
            case Pages.AUTHORITIES:
                this.cachedFetchPageToolbarDataAuthoritiesResponseDto = null;
                break;
            case Pages.REGISTRATIONS_LICENCES:
                this.cachedFetchPageToolbarDataRegistrationsLicensesResponseDto = null;
                break;
            default:
                this.clearCacheForAllPages();
                break;
        }
    }

    private clearCacheForAllPages() {
        this.cachedFetchPageToolbarDataFormDResponseDto = null;
        this.cachedFetchPageToolbarDataAuthoritiesResponseDto = null;
        this.cachedFetchPageToolbarDataRegistrationsLicensesResponseDto = null;
    }
}
