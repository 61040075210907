import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';

import {PageToolbarComponent} from './components/page-toolbar/page-toolbar.component';
import {UxAllModule} from '@eui/components/legacy';
import {EuiAllModule} from '@eui/components';
import {TableComponent} from './components/table/table.component';
import {BodyAdvancedFilterComponent} from './components/advanced-filter/body/body-advanced-filter.component';
import {FooterAdvancedFilterComponent} from './components/advanced-filter/footer/footer-advanced-filter/footer-advanced-filter.component';
import {RedirectComponent} from './components/redirect/redirect.component';
import {JoinPipe} from './pipes/join.pipe';
import {PrivacyStatementDialogBodyComponent} from './components/privacy-statement-dialog-body/privacy-statement-dialog-body.component';
import {FormatNumberToLocaleStringPipe} from "./pipes/format-number.pipe";
import {PresentNegativeNumberAsDashPipe} from "./pipes/presentNegativeNumberAsDash.pipe";


@NgModule({
    imports: [
        UxAllModule,
        EuiAllModule,
        TranslateModule,
    ],
    declarations: [
        PageToolbarComponent,
        TableComponent,
        BodyAdvancedFilterComponent,
        FooterAdvancedFilterComponent,
        RedirectComponent,
        JoinPipe,
        FormatNumberToLocaleStringPipe,
        PrivacyStatementDialogBodyComponent,
        PresentNegativeNumberAsDashPipe
    ],
    exports: [
        UxAllModule,
        EuiAllModule,
        TranslateModule,
        PageToolbarComponent,
        TableComponent,
        BodyAdvancedFilterComponent,
        FooterAdvancedFilterComponent,
        JoinPipe,
        FormatNumberToLocaleStringPipe,
        PrivacyStatementDialogBodyComponent,
        PresentNegativeNumberAsDashPipe
    ],
    providers: [
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    ],
})
export class SharedModule {}
