import {TranslateService} from '@ngx-translate/core';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {PageToolbarOptionsWithReset} from './models/page-toolbar-options.model';
import {PageToolbarService} from './page-toolbar.service';
import {EuiDialogComponent} from '@eui/components/eui-dialog';
import {EuiPopoverComponent} from '@eui/components/eui-popover';
import {FetchPageToolbarDataAuthoritiesResponseDto, FetchPageToolbarDataEncodeCasesResponseDto, FetchPageToolbarDataResponseDto, FilterOption, hasFetchPageToolbarDataResponseDtoCompetentAuthoritiesField, hasFetchPageToolbarDataResponseDtoRecordsField, hasFetchPageToolbarDataResponseDtoYearQuarterField, hasFetchPageToolbarDataResponseDtoYearsField} from './dtos/page-toolbar-data-options.dto';
import {Pages, EncodingEvent} from '../../enums/enums';
import {SubstanceListItemDto} from '../../../features/substances/dtos/fetch-substances-list-response.dto';
import {Subscription} from 'rxjs';
import {FormUtilsService} from '../../services/form-utils.service';
import {AdvancedFiltersExtended} from '../advanced-filter/models/advanced-filter.model';
import {CheckCompleteEncodeCasesRequestDto, CheckCompleteEncodeCasesResponseErrorDto} from "./dtos/check-complete-encode-cases.dto";
import {CommonUtilsService} from "../../services/common-utils.service";

@Component({
    selector: 'page-toolbar',
    templateUrl: './page-toolbar.component.html',
    styleUrls: ['./page-toolbar.component.scss']
})
export class PageToolbarComponent implements OnInit, OnDestroy {
    @ViewChild('dialog') dialog: EuiDialogComponent;
    @ViewChild('popover') popover: EuiPopoverComponent;
    @ViewChild('completeConfirmationDialog') completeConfirmationDialog: EuiDialogComponent;
    @ViewChild('incompleteConfirmationDialog') incompleteConfirmationDialog: EuiDialogComponent;
    @ViewChild('formDCompleteFlagError') formDCompleteFlagErrorDialog: EuiDialogComponent;

    @Input() page: Pages;
    @Input() showYearQuarter = false;
    @Input() showEncodingCompleted = false;
    @Input() merge: SubstanceListItemDto[] = null;
    @Input() showHideFiltersButton = false;
    @Input() showingFilters = false;
    @Input() showAdvancedSearch = false;
    @Input() showResetFilter = false;
    @Input() showExportXLSFilter = false;
    @Input() showYear = false;
    @Input() showCompetentAuthorities = false;
    @Input() showRecords = false;
    @Input() showAddNew = false;
    @Input() showSearchAllRecords = false;
    @Input() showBulkUpload = false;
    @Input() showGenerateReport = false;
    @Input() encodingCompleteTooltipText = '';
    @Input() completeConfirmationDialogTitle = '';
    @Input() completeConfirmationDialogText = '';
    @Input() incompleteConfirmationDialogTitle = '';
    @Input() incompleteConfirmationDialogText = '';
    @Input() completeButtonText = '';
    @Input() incompleteButtonText = '';
    @Input() completeLabel = '';
    @Input() incompleteLabel = '';
    @Input() encodingCompleted: boolean;
    @Input() disableEncodingCompleted = false;
    @Input() shouldCheckBeforeEncoding: boolean = false;
    @Input() showMerge: boolean = false;
    @Input() mergeTooltipText: string = '';
    @Input() resetFilterTooltipText: string = '';
    @Input() exportXlsTooltipText: string = '';
    @Input() showHideTooltipText: string = '';
    @Input() advancedFilterTooltipText: string = '';
    @Input() searchAllRecordsTooltipText: string = '';
    @Input() uploadTooltipText: string = '';
    @Input() addNewTooltipText: string = '';
    @Input() generateReportTooltipText: string = '';


    @Output() addNewClick = new EventEmitter<void>();
    @Output() mergeClick = new EventEmitter<void>();
    @Output() searchAllRecordsClick = new EventEmitter<void>();
    @Output() bulkUploadClick = new EventEmitter<void>();
    @Output() encodingComplete = new EventEmitter<EncodingEvent>();
    @Output() showHideFilters = new EventEmitter<void>();
    @Output() exportXLSClick = new EventEmitter<void>();
    @Output() generateReport = new EventEmitter<void>();

    form: FormGroup;

    yearQuarter: FilterOption[];
    years: FilterOption[];
    records: FilterOption[];
    competentAuthorities: FilterOption[];

    filterData: FetchPageToolbarDataResponseDto;

    mergeSubstancesInfoTitle: string = this.translateService.instant('dg.grow.merge.substances.information.title');
    formDCompleteFlagErrorTitle = this.translateService.instant('dg.grow.table.encode.cases.incomplete.encoding.error.dialog.title');
    formDCompleteFlagErrorText = this.translateService.instant('dg.grow.table.encode.cases.incomplete.encoding.error.dialog.text', {year: 0});

    existingAdvancedFilterData: AdvancedFiltersExtended;

    currentDate = new Date();


    private filterDataSubscription: Subscription;

    constructor(
        private formUtilsService: FormUtilsService,
        private translateService: TranslateService,
        private pageToolbarService: PageToolbarService,
        private commonUtilsService: CommonUtilsService,
    ) { }

    ngOnInit(): void {
        if (this.page === Pages.ENCODE_CASES) {
            this.filterData =
                FetchPageToolbarDataEncodeCasesResponseDto.fromObj({yearQuarter: this.pageToolbarService.fetchYearQuarterOptions()});
            if (this.filterData) {
                this.initializeOptions();
                this.initializeForm();
                this.filterChanged();
            }
        } else if(this.page === Pages.FORM_D){
            this.pageToolbarService.fetchFilterDataForPage(this.page, true).subscribe({
                next: (result) => {
                    this.filterData = result;
                    if (this.filterData) {
                        this.initializeOptions();
                        this.initializeForm();
                        this.filterChanged();
                    }
                }
            });
        } else if (this.page === Pages.AUTHORITIES) {
            this.filterData =
                FetchPageToolbarDataAuthoritiesResponseDto.fromObj({years: this.pageToolbarService.fetchYearOptions()});
            if (this.filterData) {
                this.initializeOptions();
                this.initializeForm();
                this.filterChanged();
            }
        } else {
            this.initializeFormEmpty();
            this.pageToolbarService.fetchFilterDataForPage(this.page, true).subscribe({
                next: (result) => {
                    this.filterData = result;
                    if (this.filterData) {
                        this.initializeOptions();
                        this.initializeForm();
                        this.filterChanged();
                    }
                }
            });
        }
    }

    initializeForm() {
        this.form = new FormGroup({});

        if (this.showYearQuarter) {
            this.formUtilsService.addOrSetControl(this.form, 'yearQuarter',
                new FormControl({ value: this.yearQuarter?.find(y => y.selected)?.value, disabled: false }, []));
        }

        if (this.showYear) {
            if (this.page === Pages.FORM_D) {
                const currentMonth = this.currentDate.getMonth();
                const currentYear = this.currentDate.getFullYear();
                let defaultYear: number;
                if (currentMonth < 6) {
                    defaultYear = currentYear - 1;
                } else {
                    defaultYear = currentYear;
                }
                this.years.forEach((year) => {
                    if (parseInt(year.value) === defaultYear) {
                        year.selected = true;
                    } else {
                        year.selected = false;
                    }
                });
            }
            this.formUtilsService.addOrSetControl(this.form, 'year',
                new FormControl({ value: this.years.find(y => y.selected)?.value, disabled: false }, []));
        }

        if (this.showRecords) {
            this.formUtilsService.addOrSetControl(this.form, 'records',
                new FormControl({ value: this.records.find(y => y.selected)?.value, disabled: false }, []));
        }

        if (this.showCompetentAuthorities) {
            this.formUtilsService.addOrSetControl(this.form, 'competentAuthority',
                new FormControl({ value: this.competentAuthorities.find(y => y.selected)?.value, disabled: false }, []));
        }
    }

    initializeFormEmpty() {
        this.form = new FormGroup({});

        if (this.showYearQuarter) {
            this.form.addControl('yearQuarter', new FormControl({ value: [], disabled: false }, []));
        }

        if (this.showYear) {
            this.form.addControl('year', new FormControl({ value: [], disabled: false }, []));
        }

        if (this.showRecords) {
            this.form.addControl('records', new FormControl({ value: [], disabled: false }, []));
        }

        if (this.showCompetentAuthorities) {
            this.form.addControl('competentAuthority', new FormControl({ value: [], disabled: false }, []));
        }
    }

    initializeOptions() {
        if (this.showYearQuarter && hasFetchPageToolbarDataResponseDtoYearQuarterField(this.filterData)) {
            this.yearQuarter = this.filterData?.yearQuarter;
        }

        if (this.showYear && hasFetchPageToolbarDataResponseDtoYearsField(this.filterData)) {
            this.years = this.filterData?.years;
        }

        if (this.showCompetentAuthorities && hasFetchPageToolbarDataResponseDtoCompetentAuthoritiesField(this.filterData)) {
            this.competentAuthorities = this.filterData?.competentAuthorities;
        }

        if (this.showRecords && hasFetchPageToolbarDataResponseDtoRecordsField(this.filterData)) {
            this.records = this.filterData?.records;
        }

    }

    createExistingAdvancedFiltersFormGroup(adv: AdvancedFiltersExtended): FormGroup{
        if (
            adv &&
            adv.advancedFilterRows && adv.advancedFilterRows.length > 0 &&
            adv.chosenRule
        ) {
            let array: FormGroup[] = [];
            adv.advancedFilterRows.forEach(row => {
                const tempFormGroup = new FormGroup({
                    column: new FormControl({ value: row.column, disabled: false }, []),
                    filterType: new FormControl({ value: row.filterType, disabled: false }, []),
                    userInput: new FormControl({ value: row.userInput, disabled: false }, []),
                    columnType: new FormControl({ value: row.columnType, disabled: false }, []),
                    keywordType: new FormControl({ value: row.keywordType, disabled: false }, []),
                });
                array.push(tempFormGroup);
            });

            const advancedFiltersForm = new FormGroup({
                advancedFilterRows: new FormArray(array),
                chosenRule: new FormControl({value: adv.chosenRule, disabled:false}, [])
            });

            let existingAdvancedFilters = new FormGroup({});
            existingAdvancedFilters.addControl('advancedFilters', advancedFiltersForm);
            return existingAdvancedFilters;


        }

    }

    filterChanged(resetClicked?: boolean) {
        if (this.form?.value) {
            const obj = this.form.value;

            if(resetClicked){
                obj['resetClicked'] = resetClicked;
            }

            this.existingAdvancedFilterData = AdvancedFiltersExtended.fromObj(obj.advancedFilters);

            this.pageToolbarService.setToolbars(PageToolbarOptionsWithReset.fromObj(obj));
        }
    }

    encodingChanged() {
        if (this.shouldCheckBeforeEncoding) {
            this.encodingChangedWithCheck()
        } else {
            this.encodingChangedNoCheck();
        }
    }

    confirmEncodingComplete() {
        this.completeConfirmationDialog.closeDialog();
        this.encodingComplete.emit(EncodingEvent.COMPLETE_ENCODING);
    }

    cancelEncodingComplete() {
        this.completeConfirmationDialog.closeDialog();
    }

    confirmEncodingIncomplete() {
        this.incompleteConfirmationDialog.closeDialog();
        this.encodingComplete.emit(EncodingEvent.INCOMPLETE_ENCODING);
    }

    cancelEncodingIncomplete() {
        this.incompleteConfirmationDialog.closeDialog();
    }

    closeFormDCompleteError() {
        this.formDCompleteFlagErrorDialog.closeDialog();
    }

    resetFilter() {
        this.initializeForm();
        this.filterChanged(true);
    }

    exportXLS() {
        this.exportXLSClick.emit();
    }

    openAdvancedFilter() {

        this.dialog.openDialog();
    }

    openPopover(e: any) {
        if (this.popover.isPopoverOpen) {
            this.popover.closePopover();
        } else {
            this.popover.openPopover(e.target);
        }
    }

    closeAdvancedFilters() {
        this.form.removeControl('advancedFilters');

        if (this.existingAdvancedFilterData) {

            const newFormGroup = this.createExistingAdvancedFiltersFormGroup(this.existingAdvancedFilterData);
            this.form.addControl('advancedFilters', newFormGroup.controls['advancedFilters'] as FormGroup); //Keep saved data.

        }

        this.dialog.closeDialog();
    }

    findClicked() {
        let isInvalid = false;
        if (this.form.value.advancedFilters &&
            this.form.value.advancedFilters.advancedFilterRows &&
            this.form.value.advancedFilters.advancedFilterRows.length > 0) {
            this.form.value.advancedFilters.advancedFilterRows.forEach((row,index) => {
                if (!row.userInput && row.userInput!==0) {
                    isInvalid = true;
                    (((this.form.controls['advancedFilters'] as FormGroup).get('advancedFilterRows') as FormArray)
                    .controls[index] as FormGroup).get('userInput').addValidators(Validators.required);
                    (((this.form.controls['advancedFilters'] as FormGroup).get('advancedFilterRows') as FormArray)
                    .controls[index] as FormGroup).get('userInput').patchValue(null);
                    (((this.form.get('advancedFilters') as FormGroup).get('advancedFilterRows') as FormArray)
                    .controls[index] as FormGroup).get('userInput').markAsTouched();
                }
            });
        }
        if (isInvalid) {
            return;
        }
        this.filterChanged();
        this.dialog.closeDialog();
    }

    getEncodingCompletedButtonTooltip(): string {
        return this.encodingCompleted && this.disableEncodingCompleted ?
            this.page === Pages.FORM_D?
                this.translateService.instant('dg.grow.formd.cases.page.toolbar.encoding.disabled.incomplete.tooltip') // Form D completed && disabled
                : this.translateService.instant('dg.grow.encode.cases.page.toolbar.encoding.disabled.incomplete.tooltip') // Encoded cases completed Quarter && disabled
            : this.encodingCompleteTooltipText; // Mark as complete
    }

    ngOnDestroy(): void {
        if (this.filterDataSubscription) {
            this.filterDataSubscription.unsubscribe();
        }
    }

    private encodingChangedNoCheck() {
        if (!this.encodingCompleted) {
            this.completeConfirmationDialog.openDialog();
        } else {
            this.incompleteConfirmationDialog.openDialog();
        }
    }

    private encodingChangedWithCheck() {
        if (!this.encodingCompleted) {
            this.completeConfirmationDialog.openDialog();
        } else {
            const initialDisableEncodingCompletedState: boolean = this.disableEncodingCompleted;
            this.disableEncodingCompleted = true;
            const pageToolbarOptionsWithReset: PageToolbarOptionsWithReset = PageToolbarOptionsWithReset.fromObj(this.form.value);
            const year: number = parseInt(pageToolbarOptionsWithReset.yearQuarter.slice(0, 4));
            const checkCompleteEncodeCasesRequestDto: CheckCompleteEncodeCasesRequestDto = CheckCompleteEncodeCasesRequestDto.fromObj({yearSelected: year});
            this.pageToolbarService.checkCanEncodeIncompleteCase(checkCompleteEncodeCasesRequestDto).subscribe({
                next: (result) => {
                    if(result.canComplete) {
                        this.incompleteConfirmationDialog.openDialog();
                    }
                    else {
                        this.formDCompleteFlagErrorText = this.translateService.instant('dg.grow.table.encode.cases.incomplete.encoding.error.dialog.text', {year: year});
                        this.formDCompleteFlagErrorDialog.openDialog();
                    }
                    this.disableEncodingCompleted = initialDisableEncodingCompletedState;
                },
                error: (error) => {
                    this.commonUtilsService.getResponseErrorAndDisplayGenericErrors(error,
                        CheckCompleteEncodeCasesResponseErrorDto.fromObj,
                        this.translateService.instant('dg.grow.encode.cases.complete.encode.case.growl.error.title')
                    );
                    this.disableEncodingCompleted = initialDisableEncodingCompletedState;
                }
            });
        }
    }
}
