import {EuiEnvConfig} from '@eui/core';

export const environment: EuiEnvConfig = {
    production: false,
    enableDevToolRedux: true,
    envDynamicConfig: {
        uri: 'assets/openid-login-config.json',
        deepMerge: true,
        merge: ['modules'],
    },
};

export const POST_LOGIN_REDIRECT_URI = 'https://test.drug-precursors.ec.europa.eu/dprcft-fe';
const EULOGIN_POST_LOGOUT_REDIRECT_URI = 'https://test.drug-precursors.ec.europa.eu/dprcft-fe';
export const POST_LOGOUT_REDIRECT_URI = 'https://ecas.acceptance.ec.europa.eu/cas/logout' + '?post_logout_redirect_uri=' + encodeURIComponent(EULOGIN_POST_LOGOUT_REDIRECT_URI);

const DOMAIN_CASES = 'https://api.test.drug-precursors.ec.europa.eu/dprcft-cases';
const DOMAIN_OPERATORS = 'https://api.test.drug-precursors.ec.europa.eu/dprcft-operators';
const DOMAIN_LISTS = 'https://api.test.drug-precursors.ec.europa.eu/dprcft-reference';
const SECURITY_DOMAIN_ADMINISTRATION = 'https://api.test.drug-precursors.ec.europa.eu/dprcft-security';
const DOMAIN_REPORTS = 'https://api.test.drug-precursors.ec.europa.eu/dprcft-reports';

export const CASES_API_URL = DOMAIN_CASES + '/api/cases';
export const FORMD_API_URL = DOMAIN_CASES + '/api/form-d';
export const OPERATORS_API_URL = DOMAIN_OPERATORS + '/api/operators';
// export const REPORTS_API_URL = "http://localhost:8182/api";
export const LISTS_API_URL = DOMAIN_LISTS + '/api/reference-data';
export const ADMINISTRATION_API_URL = SECURITY_DOMAIN_ADMINISTRATION + '/api/security-administration';
export const REPORTS_API_URL = DOMAIN_REPORTS + '/api/reports';

export const LINKS = {
    HOME_REGISTER: 'https://webgate.ec.europa.eu/cas/eim/external/register.cgi',
    HOME_ADMINISTRATOR:  'mailto:GROW-DRUG-PRECURSORS@ec.europa.eu',
    HOME_FREEDOM_SECURITY_JUSTICE: 'https://home-affairs.ec.europa.eu/policies/internal-security/organised-crime-and-human-trafficking/drug-policy_en',
    HOME_INTERNAL_MARKET_INDUSTRY_ENTREPRENEURSHIP: 'https://single-market-economy.ec.europa.eu/sectors/chemicals/chemicals-legislation_en',
    HOME_DIRECTORATE_GENERAL_TAXATION_CUSTOMS_UNION: 'https://taxation-customs.ec.europa.eu/customs-4/prohibitions-and-restrictions/drug-precursors-control_en',
    FOOTER_PRIVACY_STATEMENT: 'https://webgate.ec.europa.eu/drugprecursors/pdf/Privacy_Statement.pdf',
    FOOTER_CONTACT_US: 'mailto:GROW-DRUG-PRECURSORS@ec.europa.eu'
};

export const PROTECTED_BACKEND_ENDPOINTS = [
    DOMAIN_CASES + '/',
    SECURITY_DOMAIN_ADMINISTRATION + '/',
    DOMAIN_LISTS + '/',
    DOMAIN_OPERATORS + '/',
    DOMAIN_REPORTS + '/',
];

export const SHOW_HEADER_ENVIRONMENT: boolean = true;
export const HEADER_ENVIRONMENT: string = "test"
