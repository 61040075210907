import {ColumnType, KeywordType, Pages} from '../../../enums/enums';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {AdvancedFilterService} from '../advanced-filter.service';
import {FetchAdvancedFilterOptions} from '../models/fetch-advanced-filters-options.model';
import {AdvancedFilterColumnOption, AdvancedFilterOption} from '../models/advanced-filter.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'body-advanced-filter',
  templateUrl: './body-advanced-filter.component.html',
})
export class BodyAdvancedFilterComponent implements OnInit, OnDestroy {

    @Input() filtersForm: FormGroup;
    @Input() page: Pages;

    readonly KeywordType = KeywordType;

    advancedFiltersForm: FormGroup;
    advancedFilterRows: FormArray;

    columns: AdvancedFilterColumnOption[];
    filterTypesNonNumeric: AdvancedFilterOption[];
    filterTypesNumeric: AdvancedFilterOption[];
    filterTypesCategorical: AdvancedFilterOption[];
    keywordTypesYesNo: AdvancedFilterOption[];
    keywordTypesStatus: AdvancedFilterOption[];
    keywordTypesCompleted: AdvancedFilterOption[];
    keywordTypesTypeOfAuthority: AdvancedFilterOption[];

    data: FetchAdvancedFilterOptions;
    rules: AdvancedFilterOption[];

    private advancedSubscription: Subscription;

    constructor(
        private advancedFilterService: AdvancedFilterService,
    ) {}

    ngOnInit(): void {
        // reset advanced filters
        this.advancedSubscription = this.advancedFilterService.advancedFilterSubject.subscribe({
            next: (value) => {
                if(value) {
                    this.initializeForm();
                }
            }
        });

        if(this.filtersForm){
            this.data = this.advancedFilterService.fetchAdvancedFilter(this.page);
            this.initializeOptions();

            if(this.filtersForm?.contains('advancedFilters')){
                this.updateForm();
            } else {
                this.initializeForm();
            }

        }
    }

    ngOnDestroy(): void {
        if(this.advancedSubscription) {
            this.advancedSubscription.unsubscribe();
        }
    }

    initializeOptions(){
        this.columns = this.data?.columns;
        this.filterTypesNonNumeric = this.data?.filterTypes?.nonNumeric;
        this.filterTypesNumeric = this.data?.filterTypes?.numeric;
        this.filterTypesCategorical = this.data?.filterTypes?.categorical;
        this.keywordTypesYesNo = this.data?.keywordValues?.yesNo;
        this.keywordTypesStatus = this.data?.keywordValues?.status;
        this.keywordTypesCompleted = this.data?.keywordValues?.completed;
        this.keywordTypesTypeOfAuthority = this.data?.keywordValues?.typeOfAuthority;
        this.rules = this.data?.rules;
    }

    initializeForm(){
        if (this.filtersForm?.contains('advancedFilters')) {
            this.filtersForm.removeControl('advancedFilters');
        }

        this.advancedFiltersForm = new FormGroup({
            advancedFilterRows: new FormArray([]),
            chosenRule: new FormControl({value: this.rules.find(c => c?.selected)?.value, disabled:false},[])
        });

        this.filtersForm.addControl('advancedFilters', this.advancedFiltersForm);
        this.advancedFilterRows = this.advancedFiltersForm?.controls['advancedFilterRows'] as FormArray;
        this.addRow();
    }

    updateForm(){
        this.advancedFiltersForm = this.filtersForm?.controls['advancedFilters'] as FormGroup;
        this.advancedFilterRows = this.advancedFiltersForm?.controls['advancedFilterRows'] as FormArray;
    }

    defaultRow(): FormGroup {
        const preselectedColumn = this.columns?.find(col => col.selected);

        let preselectedFilterType: string | undefined;
        if(preselectedColumn?.columnType === ColumnType.NUMERIC){
            preselectedFilterType = this.filterTypesNumeric?.find(type => type.selected)?.value;
        } else if(preselectedColumn?.columnType === ColumnType.CATEGORICAL){
            preselectedFilterType = this.filterTypesCategorical?.find(type => type.selected)?.value;
        } else if (preselectedColumn?.columnType === ColumnType.NON_NUMERIC) {
            preselectedFilterType = this.filterTypesNonNumeric?.find(type => type.selected)?.value;
        }

        let preselectedKeywordType: string | undefined;
        if(preselectedColumn?.keywordType === KeywordType.YESNO){
            preselectedKeywordType = this.keywordTypesYesNo?.find(type => type.selected)?.value;
        } else if (preselectedColumn?.keywordType === KeywordType.STATUS) {
            preselectedKeywordType = this.keywordTypesStatus?.find(type => type.selected)?.value;
        } else if (preselectedColumn?.keywordType === KeywordType.COMPLETED) {
            preselectedKeywordType = this.keywordTypesCompleted?.find(type => type.selected)?.value;
        }

        return new FormGroup({
            column: new FormControl({ value: preselectedColumn?.value, disabled: false }, []),
            filterType: new FormControl({ value: preselectedFilterType, disabled: false }, []),
            userInput: new FormControl({ value: preselectedKeywordType, disabled: false }, []),
            columnType: new FormControl({ value: preselectedColumn?.columnType, disabled: false }, []),
            keywordType: new FormControl({ value: preselectedColumn?.keywordType, disabled: false }, []),
        });
    }

    addRow(){
        this.advancedFilterRows.push(this.defaultRow());
    }

    removeRow(rowIndex: number){
        this.advancedFilterRows.removeAt(rowIndex);
        if(this.advancedFilterRows.length === 0) {
            this.addRow();
        }
    }

    columnChanged(rowIndex: number){
        const columnChanged = this.columns?.find(col => col.value === this.advancedFilterRows.value[rowIndex].column);

        let preselectedFilterType: string | undefined;
        if(columnChanged?.columnType === ColumnType.NUMERIC){
            preselectedFilterType = this.filterTypesNumeric?.find(type => type.selected)?.value;
        } else if(columnChanged?.columnType === ColumnType.CATEGORICAL){
            preselectedFilterType = this.filterTypesCategorical?.find(type => type.selected)?.value;
        } else if (columnChanged?.columnType === ColumnType.NON_NUMERIC) {
            preselectedFilterType = this.filterTypesNonNumeric?.find(type => type.selected)?.value;
        }

        let preselectedKeywordType: string | undefined;
        if(columnChanged?.keywordType === KeywordType.YESNO){
            preselectedKeywordType = this.keywordTypesYesNo?.find(type => type.selected)?.value;
        } else if (columnChanged?.keywordType === KeywordType.STATUS) {
            preselectedKeywordType = this.keywordTypesStatus?.find(type => type.selected)?.value;
        } else if (columnChanged?.keywordType === KeywordType.COMPLETED) {
            preselectedKeywordType = this.keywordTypesCompleted?.find(type => type.selected)?.value;
        }

        (this.advancedFilterRows.controls[rowIndex] as FormGroup).get('filterType')?.patchValue(preselectedFilterType);
        (this.advancedFilterRows.controls[rowIndex] as FormGroup).get('userInput')?.patchValue(preselectedKeywordType);
        (this.advancedFilterRows.controls[rowIndex] as FormGroup).get('columnType')?.patchValue(columnChanged?.columnType );
        (this.advancedFilterRows.controls[rowIndex] as FormGroup).get('keywordType')?.patchValue(columnChanged?.keywordType);
    }

}
