<form *ngIf="form" [formGroup]="form" enctype="multipart/form-data">
    <div class="eui-u-flex eui-u-flex-align-items-end flex-wrap">
        <div class="eui-u-display-flex eui-u-flex-align-items-end flex-grow flex-wrap-xs left-group ">
            <!--            Year/Quarter-->
            <div class="eui-u-width-auto flex-grow-xs flex-grow-sm flex-basis-100-xs " *ngIf="showYearQuarter">
                <label class="bolded" euiLabel for="yearQuarter">{{ 'dg.grow.filters.year.quarter.label' | translate }}</label>
                <select euiSelect name="yearQuarter" id="yearQuarter" formControlName="yearQuarter" (change)="filterChanged()">
                    <option *ngFor="let year of yearQuarter" [ngValue]="year?.value">{{year?.label}}</option>
                </select>
            </div>
            <!--            Year-->
            <div class="eui-u-width-auto flex-grow-xs flex-grow-sm flex-basis-100-xs year-sub-group" *ngIf="showYear">
                <label class="bolded" euiLabel for="year">{{ 'dg.grow.filters.year.label' | translate }}</label>
                <select euiSelect name="year" id="year" formControlName="year" (change)="filterChanged()">
                    <option *ngFor="let year of years" [ngValue]="year?.value">{{year?.label}}</option>
                </select>
            </div>
            <!--            RecordsFilter-->
            <div class="eui-u-width-auto flex-grow-xs flex-grow-sm flex-basis-100-xs " *ngIf="showRecords">
                <label class="bolded" euiLabel for="records">{{ 'dg.grow.filters.records.label' | translate }}</label>
                <select euiSelect name="records" id="records" formControlName="records" (change)="filterChanged()">
                    <option *ngFor="let record of records" [ngValue]="record?.value">{{record?.label}}</option>
                </select>
            </div>
            <!--            Merge Substances-->
            <div class="eui-u-width-auto flex-grow-xs flex-grow-sm flex-basis-100-xs  eui-u-display-flex eui-u-flex-align-items-center" *ngIf="page==='substances'">
                <button *ngIf="showMerge" euiButton [euiDisabled]="!(merge[0] && merge[1])" [euiTooltip]="mergeTooltipText" (click)="mergeClick.emit() ">
                    <eui-icon-svg [icon]="!(merge[0] && merge[1]) ? 'eui-ecl-spreadsheet' : 'eui-ecl-copy'"></eui-icon-svg>
                    <span euiLabel>{{ 'dg.grow.filters.merge.selected.substances.label' | translate }}</span>
                </button>
                <eui-icon-svg *ngIf="showMerge" [icon]="popover.isPopoverOpen ? 'eui-question-circle-o' : 'eui-question-circle'" fillColor="info-100" euiInfo
                    (click)="openPopover($event)"></eui-icon-svg>
            </div>
            <!--            Competent Authorities-->
            <div class="eui-u-width-auto flex-grow-xs flex-grow-sm flex-basis-100-xs " *ngIf="showCompetentAuthorities">
                <label class="bolded" euiLabel for="CA">{{'dg.grow.filters.competent.authorities.label' | translate}}</label>
                <select euiSelect name="CA" id="CA" formControlName="competentAuthority" (change)="filterChanged()">
                    <option *ngFor="let ca of competentAuthorities" [ngValue]="ca?.value">{{ca?.label}}</option>
                </select>
            </div>
            <!--            Encoding Complete-->
            <eui-chip *ngIf="showEncodingCompleted" [euiSuccess]="encodingCompleted" [euiWarning]="!encodingCompleted"
                class="eui-u-p-none eui-u-ph-s">
                <div *ngIf="encodingCompleted; then complete else incomplete"></div>
                <ng-template #complete>
                    <eui-icon-svg icon="eui-ecl-check-filled"></eui-icon-svg>
                    <span euiLabel class="eui-u-ml-none">{{ completeLabel }}</span>
                </ng-template>
                <ng-template #incomplete>
                    <eui-icon-svg icon="eui-alert-circle"></eui-icon-svg>
                    <span euiLabel class="eui-u-ml-none">{{ incompleteLabel }}</span>
                </ng-template>
            </eui-chip>
            <div class="eui-u-width-auto flex-grow-xs flex-grow-sm flex-basis-100-xs" *ngIf="showEncodingCompleted"
                [euiTooltip]="getEncodingCompletedButtonTooltip()">
                <button euiButton euiPrimary [euiSecondary]="disableEncodingCompleted" [euiDisabled]="disableEncodingCompleted" (click)="encodingChanged()"
                    class="eui-u-p-none eui-u-ph-s">
                    <div *ngIf="!encodingCompleted; then complete else incomplete"></div>
                    <ng-template #complete>
                        <eui-icon-svg icon="eui-ecl-check-filled"></eui-icon-svg>
                        <span euiLabel>{{ completeButtonText }}</span>
                    </ng-template>
                    <ng-template #incomplete>
                        <eui-icon-svg icon="eui-pencil"></eui-icon-svg>
                        <span euiLabel>{{ incompleteButtonText }}</span>
                    </ng-template>
                </button>
            </div>

        </div>
        <div class="hidden-xs hidden-sm" style="flex: 1 1;"></div>
        <div class="eui-u-display-flex eui-u-flex-align-items-end right-group" *ngIf="showHideFiltersButton || showAdvancedSearch || showResetFilter">
            <!--            Show / Hide Filters-->
            <button *ngIf="showHideFiltersButton" euiButton class="hidden-xs" (click)="showHideFilters.emit()" [euiTooltip]="showHideTooltipText">
                <eui-icon-svg icon="eui-filter"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ (showingFilters ? 'dg.grow.filters.hide.filters.label' : 'dg.grow.filters.show.filters.label') | translate }}</span>
            </button>
            <!--            Advanced Filters-->
            <button *ngIf="showAdvancedSearch" euiButton [euiTooltip]="advancedFilterTooltipText" (click)="openAdvancedFilter()">
                <eui-icon-svg icon="eui-search"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ 'dg.grow.filters.advanced.search.label' | translate }}</span>
            </button>
            <button *ngIf="showResetFilter" [euiTooltip]=resetFilterTooltipText euiButton (click)="resetFilter()">
                <eui-icon-svg icon="eui-undo"></eui-icon-svg>
            </button>
            <button *ngIf="showSearchAllRecords" euiButton euiPrimary [euiTooltip]="searchAllRecordsTooltipText" (click)="searchAllRecordsClick.emit()">
                <eui-icon-svg icon="book" set="outline"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ 'dg.grow.filters.reg.licences.page.search.all.records' | translate }}</span>
            </button>
            <button *ngIf="showBulkUpload" euiButton euiPrimary [euiTooltip]="uploadTooltipText" (click)="bulkUploadClick.emit()">
                <eui-icon-svg icon="cloud-upload" set="sharp"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ 'dg.grow.filters.bulk.upload' | translate }}</span>
            </button>
            <button *ngIf="showExportXLSFilter" [euiTooltip]=exportXlsTooltipText euiButton (click)="exportXLS()">
                <eui-icon-svg icon="eui-ecl-spreadsheet"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ 'dg.grow.filters.export.xls.label' | translate }}</span>
            </button>
            <button *ngIf="showAddNew" [euiSecondary]="encodingCompleted" [disabled]="encodingCompleted" euiButton euiPrimary [euiTooltip]="addNewTooltipText" (click)="addNewClick.emit()">
                <eui-icon-svg icon="add-circle" set="sharp"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ "dg.grow.table.add.new" | translate }}</span>
            </button>
            <button *ngIf="showGenerateReport" euiButton euiPrimary [euiTooltip]="generateReportTooltipText" (click)="generateReport.emit()">
                <eui-icon-svg icon="add-circle" set="sharp"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ "dg.grow.table.add.new" | translate }}</span>
            </button>
        </div>
        <div class="eui-u-display-flex eui-u-flex-align-items-end right-group" *ngIf="showGenerateReport">
            <button  euiButton  [euiTooltip]="generateReportTooltipText" (click)="generateReport.emit()">
                <eui-icon-svg icon="eui-arrow-down"></eui-icon-svg>
                <span euiLabel class="hidden-xs">{{ "dg.grow.reports.page.toolbar.generate.report.button" | translate }}</span>
            </button>
        </div>
    </div>
</form>

<eui-dialog #dialog (close)="closeAdvancedFilters()" title="{{'dg.grow.advanced.search.modal.title' | translate}}" [width]="'50rem'">
    <body-advanced-filter *ngIf="dialog.isOpen" [filtersForm]="form" [page]="page"></body-advanced-filter>
    <eui-dialog-footer>
        <footer-advanced-filter (cancelClicked)="closeAdvancedFilters()" (findClicked)="findClicked()"></footer-advanced-filter>
    </eui-dialog-footer>
</eui-dialog>

<eui-popover #popover [title]=mergeSubstancesInfoTitle position="right" type="colored-header">
    <h2 class="eui-u-mb-m">{{'dg.grow.merge.substances.information.header' | translate}}</h2>
    <p class="eui-u-mb-m">
        <strong>1.</strong>&nbsp;{{'dg.grow.merge.substances.information.text1' | translate}}
    </p>
    <p>
        <strong>2.</strong>&nbsp;{{'dg.grow.merge.substances.information.text2' | translate}}
    </p>
</eui-popover>

<eui-dialog #completeConfirmationDialog [title]=completeConfirmationDialogTitle>
    <div>{{ completeConfirmationDialogText }}</div>
    <eui-dialog-footer>
        <div class="eui-u-flex eui-u-flex-justify-content-end">
            <button euiButton class="eui-u-mr-m" (click)="cancelEncodingComplete()">{{'dg.grow.table.complete.encoding.cancel.button' | translate}}</button>
            <button euiButton euiPrimary (click)="confirmEncodingComplete()">{{'dg.grow.table.complete.encoding.confirm.button' | translate}}</button>
        </div>
    </eui-dialog-footer>
</eui-dialog>

<eui-dialog #incompleteConfirmationDialog [title]=incompleteConfirmationDialogTitle>
    <div>{{ incompleteConfirmationDialogText }}</div>
    <eui-dialog-footer>
        <div class="eui-u-flex eui-u-flex-justify-content-end">
            <button euiButton class="eui-u-mr-m" (click)="cancelEncodingIncomplete()">{{'dg.grow.table.incomplete.encoding.cancel.button' | translate}}</button>
            <button euiButton euiPrimary (click)="confirmEncodingIncomplete()">{{'dg.grow.table.incomplete.encoding.confirm.button' | translate}}</button>
        </div>
    </eui-dialog-footer>
</eui-dialog>

<eui-dialog #formDCompleteFlagError [title]=formDCompleteFlagErrorTitle>
    <div>{{ formDCompleteFlagErrorText }}</div>
    <eui-dialog-footer>
        <div class="eui-u-flex eui-u-flex-justify-content-end">
            <button euiButton euiPrimary (click)="closeFormDCompleteError()">{{'dg.grow.table.incomplete.encoding.error.button' | translate}}</button>
        </div>
    </eui-dialog-footer>
</eui-dialog>
